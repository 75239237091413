import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import { toast } from 'react-hot-toast';
import { EntityType, StageType } from '../common/types';
import convert from 'convert-units';

function generatePopupContent(event, entity, stageType) {
    const properties = event.features[0]?.properties;
    if (!properties) return '';

    let html_str = '';
    
    if (properties.tree_num) html_str += `<strong>Tree ${properties.tree_num}</strong>`;
    if (properties.master_tree_id) html_str += `<strong>Tree ID ${properties.master_tree_id}</strong>`;
    if (properties.north_id) html_str += `<p>North Id: ${properties.north_id}</p>`;
    if (properties.south_id) html_str += `<p>South Id: ${properties.south_id}</p>`;
    if (properties.west_id) html_str += `<p>West Id: ${properties.west_id}</p>`;
    if (properties.east_id) html_str += `<p>East Id: ${properties.east_id}</p>`;
    if (properties.row_num) html_str += `<p>Row Num: ${properties.row_num}</p>`;
    if (properties.scan_id) html_str += `<p>Scan ID: ${properties.scan_id}</p>`;
    if (properties.row_id) html_str += `<p>Row ID: ${properties.row_id}</p>`;

    if (entity === EntityType.Fruits) {
        if (stageType === StageType.EarlyFruitSet) {
            if (properties.avg_minor_ax) html_str += `<p>Average Diameter: ${properties.avg_minor_ax.toFixed(2)} mm</p>`;
        } else if (properties.avg_diam) {
            html_str += `<p>Average Diameter: ${properties.avg_diam.toFixed(2)} mm</p>`;
        }
    }
    
    if (properties.variety) html_str += `<p>Variety: ${properties.variety}</p>`;

    if (entity !== EntityType.Trees) {
        if (properties.num_buds === "0" || properties.num_buds === 0) {
            html_str += `<p class='text-red-500'>This tree was not scanned or sampled in this scan</p>`;
        } else if (stageType !== StageType.EarlyFruitSet) {
            if (properties.detected_count) html_str += `<p>Detected ${entity}: ${String(properties.detected_count).slice(0, 5)} ${entity}</p>`;
            if (properties.num_buds !== properties.detected_count) html_str += `<p>Calibrated ${entity}: ${String(properties.num_buds).slice(0, 5)} ${entity}</p>`;
        }
    }

    html_str += `<hr class='border-gray-500 my-2' />`;
    
    if (properties.canopy_area_m2 && properties.canopy_area_m2 !== 0) html_str += `<p>Canopy XS Area: ${String(properties.canopy_area_m2).slice(0, 5)} m²</p>`;
    if (entity === EntityType.Trees) {
        if (properties.xs_area) html_str += `<p>Trunk XS Area: ${String(properties.xs_area).slice(0, 5)} sq cm</p>`;
        if (properties.width) html_str += `<p>Trunk Diameter: ${String(properties.width).slice(0, 5)} in</p>`;
        if (properties.canopy_height_m && properties.canopy_area_m2 !== 0) html_str += `<p>Canopy Height: ${String(properties.canopy_height_m).slice(0, 5)} m</p>`;
    }

    html_str += `<i><b>(${String(event.features[0].geometry.coordinates[0]).slice(0, 11)}, ${String(event.features[0].geometry.coordinates[1]).slice(0, 9)})</b></i>`;

    return html_str;
}

let currentPopup = null;

export const popupConfigs = {
    'sections': {
        onMouseEnter: (event) => {
            const map = event.target;
            let html_str = '<strong>Section Code: ' + event.features[0].properties.section_code + '</strong>';

            if (event.features[0].properties.manual_count) {
                html_str += '<p>Manual Count: ' + event.features[0].properties.manual_count + ' fruits</p>';
                html_str += '<p>Last Manual Count: ' + event.features[0].properties.manual_count_timestamp + '</p>';
            } else {
                html_str += '<p>No Manual Counts</p>';
            }
            
            if (event.features[0].properties.detections) {
                html_str += '<p>Detections: ' + event.features[0].properties.detections + ' fruits</p>';
                html_str += '<p>Last Detection: ' + event.features[0].properties.detection_timestamp + '</p>';
            } else {
                html_str += '<p>No Detected Counts</p>';
            }
            
            if (event.features[0].properties.detections && event.features[0].properties.manual_count) {
                html_str += '<p>Calibration Ratio: ' + event.features[0].properties.calib_ratio + '</p>';
            } 
            map.getCanvas().style.cursor = 'pointer';
            if (currentPopup) currentPopup.remove(); // Ensure the previous popup is removed
            currentPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            })
                .setLngLat(event.features[0].geometry.coordinates)
                .setHTML(html_str)
                .addTo(map);
        },
        onClick: async function (event, setManualCalibrationDialog, setSelectedCalibrationSection) {
            setSelectedCalibrationSection(event.features[0].properties);
            setManualCalibrationDialog(true);
        }
    },
    'trees-point': {
        onMouseEnter: (event, entity, stageType) => {
            const map = event.target;
            map.getCanvas().style.cursor = 'pointer';
            const popupContent = generatePopupContent(event, entity, stageType);
            if (currentPopup) currentPopup.remove(); // Ensure the previous popup is removed
            currentPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            })
                .setLngLat(event.features[0].geometry.coordinates)
                .setHTML(popupContent)
                .addTo(map);
        }
    },
    'trees-audit': {
        onMouseEnter: (event, entity, stageType) => {
            const map = event.target;

            const properties = event.features[0]?.properties;
            map.getCanvas().style.cursor = 'pointer';
            let popupContent = generatePopupContent(event, entity, stageType);
            if (properties.frame_id) popupContent += `<p>Frame ID: ${properties.frame_id}</p>`;
            if (currentPopup) currentPopup.remove(); // Ensure the previous popup is removed
            currentPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            })
                .setLngLat(event.features[0].geometry.coordinates)
                .setHTML(popupContent)
                .addTo(map);
        }
    },
    'trees-size': {
        onMouseEnter: (event, entity, stageType) => {
            const map = event.target;
            map.getCanvas().style.cursor = 'pointer';
            const popupContent = generatePopupContent(event, entity, stageType);
            if (currentPopup) currentPopup.remove(); // Ensure the previous popup is removed
            currentPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            })
                .setLngLat(event.features[0].geometry.coordinates)
                .setHTML(popupContent)
                .addTo(map);
        }
    },
    'trees-point-diff': {
        onMouseEnter: (event, entity, stageType) => {
            const map = event.target;
            map.getCanvas().style.cursor = 'pointer';
            const popupContent = generatePopupContent(event, entity, stageType);
            if (currentPopup) currentPopup.remove(); // Ensure the previous popup is removed
            currentPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            })
                .setLngLat(event.features[0].geometry.coordinates)
                .setHTML(popupContent)
                .addTo(map);
        }
    },
    'trees-point-uni': {
        onMouseEnter: (event, entity, stageType) => {
            const map = event.target;
            map.getCanvas().style.cursor = 'pointer';
            const popupContent = generatePopupContent(event, entity, stageType);

            if (currentPopup) currentPopup.remove(); // Ensure the previous popup is removed
            currentPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            })
                .setLngLat(event.features[0].geometry.coordinates)
                .setHTML(popupContent)
                .addTo(map);
        }
    },
    'trees-for-row': {
        onMouseEnter: (event, entity, stageType) => {
            const map = event.target;

            const properties = event.features[0]?.properties;
            map.getCanvas().style.cursor = 'pointer';
            let popupContent = generatePopupContent(event, entity, stageType);
            if (properties.frame_id) popupContent += `<p>Frame ID: ${properties.frame_id}</p>`;
            if (currentPopup) currentPopup.remove(); // Ensure the previous popup is removed
            currentPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            })
                .setLngLat(event.features[0].geometry.coordinates)
                .setHTML(popupContent)
                .addTo(map);
        },
        onClick: async function (e) {
            console.log("click", e.features[0].properties.row_id, this.selectedRow)
            if (!this.selectedRow) {
                // setSelectedRow(e.features[0].properties.row_id);
                this.selectedRow = e.features[0].properties.row_id;
                return;
            }
            const selectRow = this.selectedRow;
            const combineRow = e.features[0].properties.row_id;
            this.selectedRow = null;
            
            const res = await toast.promise(
                axios.post('/audit/combine_rows', { row_id_1: selectRow, row_id_2: combineRow }),
                {
                    loading: 'Combining rows...',
                    success: <b>Done combining rows!</b>,
                    error: <b>An error occurred while transferring.</b>
                }
            );
        }
    },
    'trees-rld': {
        onMouseEnter: (event, entity, stageType, selectedRLDOptions) => {
            const map = event.target;
            const properties = event.features[0].properties;
            let html_str = '';

            if (properties.master_tree_id) html_str += `<strong>Tree ID ${properties.master_tree_id}</strong>`;
            html_str += `<hr class='border-gray-500 my-2' />`;

            html_str += '<strong>Leaf Discoloration (ft²)</strong>';
            let totalArea_m2 = 0;

            const rldMap = {
                'ccsa_red_m2':'Red' ,
                'ccsa_yellowred_m2': 'Red-Yellow',
                'ccsa_yellow_m2': 'Yellow' ,
                'ccsa_wilted_m2': 'Wilted',
            };

            for (const [key, value] of Object.entries(rldMap)) {
                if (selectedRLDOptions && selectedRLDOptions[key]) {
                    const area_ft2 = convert(properties[key]).from('m2').to('ft2').toFixed(2);
                    html_str += `<p>${value}: ${area_ft2} ft²</p>`;
                    totalArea_m2 += parseFloat(properties[key]) || 0;
                }
            }

            const totalArea_ft2 = convert(totalArea_m2).from('m2').to('ft2').toFixed(2);
            
            const percentArea = properties.canopy_area_m2 === 0 ? 0 : ((totalArea_m2 / properties.canopy_area_m2) * 100).toFixed(2);
            html_str += `<p><strong>Total: ${totalArea_ft2} ft² (${percentArea}%)</strong></p>`;

            html_str += `<hr class='border-gray-500 my-2' />`;

            // Add CCSA information
            const ccsa_ft2 = convert(properties.canopy_area_m2).from('m2').to('ft2').toFixed(2);
            html_str += `<p><strong>CCSA: ${ccsa_ft2} ft²</strong></p>`;

            map.getCanvas().style.cursor = 'pointer';
            if (currentPopup) currentPopup.remove();
            currentPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            })
                .setLngLat(event.features[0].geometry.coordinates)
                .setHTML(html_str)
                .addTo(map);
        }
    }
};

// Define the common onMouseLeave handler since it's the same for all
export function onMouseLeaveHandler(event) {
    event.target.getCanvas().style.cursor = '';
    if (currentPopup) {
        currentPopup.remove();
        currentPopup = null;
    }
}

export function addPopupHandlers(
    map,
    layerName,
    entity,
    stageType,
    selectedRLDOptions
) {
    if (!popupConfigs[layerName]) {
        console.error('Invalid popupConfig ID');
        return;
    }
    const layerConfig = popupConfigs[layerName];

    if (layerConfig.onMouseEnter) {
        map.on('mouseenter', layerName, (event) => {
            layerConfig.onMouseEnter(event, entity, stageType, selectedRLDOptions);
        });
        map.on('mouseleave', layerName, onMouseLeaveHandler);
    }
}

