import { faChartPie } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography, Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import ColorWheel from '../components/ColorWheel';
import FruitHueDialog from '../FruitHueDialog';

const HueLegend = ({
    treeData,
    stats,
    lowerBound,
    upperBound,
    setLowerBound,
    setUpperBound,
    config,
    isAdminUser,
    plotType
}) => {

    // Add new state for angles
    const [lowerBoundAngle, setLowerBoundAngle] = useState(lowerBound * 2);
    const [upperBoundAngle, setUpperBoundAngle] = useState(upperBound * 2);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        setLowerBoundAngle(lowerBound * 2);
        setUpperBoundAngle(upperBound * 2);
    }, [lowerBound, upperBound]);

    // Add function to calculate percentage of trees within bounds
    const calculateTreePercentage = () => {
        if (!treeData || !treeData.features || treeData.features.length === 0) return '0 (0%)';

        const treesInRange = treeData.features.filter(tree => {
            const hue = tree.properties[config.feature];

            return (
                (hue >= lowerBound && hue <= upperBound) ||
                (hue + 180 >= lowerBound && hue + 180 <= upperBound)
            );
        });

        const percentage = (treesInRange.length / treeData.features.length) * 100;
        return `${treesInRange.length.toLocaleString()} (${percentage.toFixed(1)}%)`;
    };

    // Simplified handler for button
    const handleViewAllTrees = () => {
        let lower = (stats[config.stat] + config.bounds?.[0] + 360) % 180;
        let upper = (stats[config.stat] + config.bounds?.[1] + 360) % 180;

        if (lower > upper) {
            upper += 180;
        }

        setLowerBound(lower);
        setUpperBound(upper);
    };

    const handleExpandClick = () => {
        setDialogOpen(true);
    };

    const handleBoundsChange = (newBounds) => {
        // We'll just use the first bound for now to maintain backward compatibility
        const [firstBound] = newBounds;
        setLowerBound(firstBound.lower);
        setUpperBound(firstBound.upper);
    };

    return (
        <div className='flex flex-row items-center justify-start gap-8 w-full'>


            <div className='flex flex-col items-center'>
                <ColorWheel
                    size="sm"
                    bounds={[{ lower: lowerBound, upper: upperBound }]}
                    onBoundsChange={handleBoundsChange}
                />
            </div>

            <div className='flex flex-col items-start gap-4'>
                <div className='flex flex-col gap-1'>
                    <Typography variant='h6'>{config.caption}</Typography>
                    <Typography variant='caption' color="text.secondary">
                        Click & drag the color bounds to edit the hue range
                    </Typography>
                </div>
                <div className='text-base'>
                    <div className='font-medium'>Hue Range: {Math.round(lowerBoundAngle % 360)}° - {Math.round(upperBoundAngle % 360)}°</div>
                    <div className='font-medium'>Trees: {calculateTreePercentage()}</div>
                </div>
                <div className='flex flex-row items-center gap-2'>
                    <Button
                        variant="contained"
                        onClick={handleViewAllTrees}
                        textTransform="lowercase"
                    >
                        Reset
                    </Button>
                    {isAdminUser && plotType === 'color' && (
                        <Tooltip title="Grading Estimation Tool" arrow>
                            <FontAwesomeIcon
                                icon={faChartPie}
                                className="h-[25px] text-gray-600 cursor-pointer hover:text-gray-800 z-10
                                border-2 border-gray-300 rounded-md p-1 hover:border-gray-400 hover:bg-gray-50 transition-all"
                                onClick={handleExpandClick}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>

            <FruitHueDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                stats={stats}
            />
        </div>
    );
};

export default HueLegend;
