import { Box, Dialog, DialogTitle, DialogContentText, TextField, Button, CircularProgress, Skeleton } from '@mui/material';
import { useState, useEffect } from 'react';
import ColorWheel from './components/ColorWheel';
import axios from 'axios';

const FruitHueDialog = ({ open, onClose, stats }) => {
    const [colorGrades, setColorGrades] = useState([]);
    const [gradeResults, setGradeResults] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleGradesChange({ target: { value: 1 } });
    }, []);

    const wheelAnnotations = [
        { degree: stats.p1_hue * 2, caption: "low" },
        { degree: stats.p99_hue * 2, caption: "high" }
    ];


    const handleBoundsChange = (newBounds) => {
        const updatedBounds = newBounds.map((bound, index) => ({
            lower: Math.round(bound.lower),
            upper: Math.round(bound.upper) 
        }));
        setColorGrades(updatedBounds);
    };

    // Clockwise angle diff between two angles/points on a circle
    function angleDiff(start, end) {
        if (end > start) {
            return end - start;
        } else {
            return 360 - start + end;
        }
    }

    const handleGradesChange = (event) => {
        const count = parseInt(event.target.value) || 0;
        if (count > 0) {
            const degreesPerGrade = (angleDiff(stats.p1_hue * 2, stats.p99_hue * 2)/2 / (count * 2 - 1));
            const newGrades = [];
            
            for (let i = 0; i < (count * 2); i+=2) {
                newGrades.push({
                    lower: Math.round(stats.p1_hue + i * degreesPerGrade) % 360,
                    upper: Math.round(stats.p1_hue + (i + 1) * degreesPerGrade) % 360
                });
            }
            
            setColorGrades(newGrades);
            console.log("GRADES", newGrades);
        } else {
            setColorGrades([]);
        }
    };

    const handleCalculate = async () => {
        if (colorGrades.length === 0) return;
        
        setLoading(true);
        try {
            const response = await axios.post('/util/get_hue_boundary_counts', {
                scan_id: stats.scan_id,
                boundaries: colorGrades
            });
            setGradeResults(response.data);
        } catch (error) {
            console.error('Error calculating hue boundaries:', error);
        } finally {
            setLoading(false);
        }
    };

    const getGradeColor = (lower, upper) => {
        const hue = (lower + upper) / 2;
        return `hsl(${hue * 2}, 70%, 50%)`;
    };

    const getTotalFruits = () => {
        if (!gradeResults) return 0;
        return Object.values(gradeResults).reduce((sum, grade) => sum + grade.count, 0);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
            fullWidth
            className="p-4"
            PaperProps={{
                style: {
                    maxHeight: '100vh',
                    overflowY: 'hidden'
                }
            }}
        >
            <DialogTitle className="text-2xl font-bold">Estimate Fruit Color Grades (Beta)</DialogTitle>
            <DialogContentText className="px-6 text-gray-600">
                Estimate the number of fruit that fall within each color grade.
                First, define the number of color grades you would like to use.
                Then, use the color wheel to define a color range for each grade.
                (i.e. create three color ranges for sorting fruit into A, B, and C grades)
            </DialogContentText>
            <div className="h-[80vh] p-6">
                <div className="flex items-center space-x-4">
                <TextField
                    type="number"
                    label="Number of Color Grades (max 6)"
                    defaultValue={1}
                    InputProps={{
                        inputProps: { min: 1, max: 6 }
                    }}
                    className="w-64"
                    onChange={handleGradesChange}
                />
                    <Button
                        variant="contained"
                        onClick={handleCalculate}
                        disabled={loading || colorGrades.length === 0}
                        className="mt-4 normal-case"
                    >
                        {loading ? <CircularProgress size={24} /> : 'Calculate Fruit Counts'}
                    </Button>
                </div>

                {colorGrades.length > 0 && (
                    <div className="flex">
                            <ColorWheel 
                                bounds={colorGrades} 
                                onBoundsChange={handleBoundsChange} 
                                size="lg"
                                annotations={wheelAnnotations}
                                disabled={loading}
                            />
                        <Box className="mt-4 ml-8 flex-1">
                            <h3 className="text-lg font-semibold mb-2">Color Grade Results:</h3>
                            <div className="space-y-4">
                                {colorGrades.map((grade, index) => (
                                    <div key={index} className="space-y-1">
                                        <div className="flex items-center justify-between">
                                            <span className="text-sm font-medium">
                                                Grade {index + 1}: {grade.lower * 2}° - {grade.upper * 2}°
                                            </span>
                                            {loading ? (
                                                <Skeleton width={100} />
                                            ) : gradeResults && (
                                                <span className="text-sm font-semibold">
                                                    {(gradeResults[`boundary_${index}`]?.count || 0).toLocaleString()} fruits
                                                    ({((gradeResults[`boundary_${index}`]?.count || 0) / getTotalFruits() * 100).toFixed(1)}%)
                                                </span>
                                            )}
                                        </div>
                                        {loading ? (
                                            <Skeleton variant="rectangular" className="h-8 w-full rounded-lg" />
                                        ) : gradeResults && (
                                            <div className="w-full h-8 bg-gray-100 rounded-lg overflow-hidden">
                                                <div 
                                                    className="h-full transition-all duration-500"
                                                    style={{
                                                        width: `${(gradeResults[`boundary_${index}`]?.count || 0) / getTotalFruits() * 100}%`,
                                                        backgroundColor: getGradeColor(grade.lower, grade.upper)
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {loading ? (
                                <div className="mt-4 pt-4 border-t border-gray-200">
                                    <Skeleton width={300} />
                                </div>
                            ) : gradeResults && (
                                <div className="mt-4 pt-4 border-t border-gray-200">
                                    <span className="text-sm font-semibold">
                                        Total Fruits within selected grades: {getTotalFruits().toLocaleString()}
                                    </span>
                                </div>
                            )}
                        </Box>
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default FruitHueDialog;