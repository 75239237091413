import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

export const GroundTruthDialog = ({ sectionGeojson, isGroundTruthDialogOpen, setIsGroundTruthDialogOpen, blockInfo, currentScanId, orchard, scanInfo }) => {
  const [manualCounts, setManualCounts] = useState({});
  const [selectedCounts, setSelectedCounts] = useState({});
  const [pregenerating, setPregenerating] = useState(false);

  const validDetections = sectionGeojson.features.filter((feature) => feature.properties.detected_count_id != null);
  const hasDetections = validDetections.length > 0;

  useEffect(() => {
    if (blockInfo && blockInfo.block_id) {
      fetchManualCounts(blockInfo.block_id);
    }
  }, [blockInfo]);

  const fetchManualCounts = async (blockId) => {
    try {
      const response = await axios.get('/util/get_manual_counts', {
        params: { block_id: blockId }
      });
      console.log('Manual counts:', response.data)
      setManualCounts(response.data);

      const initialSelectedCounts = {};
      sectionGeojson.features.forEach((feature) => {
        if (feature.properties.detected_count_id != null) {
          const sectionCounts = response.data[feature.properties.section_code] || [];
          const matchingCount = sectionCounts.find(count => count.manual_count_id === feature.properties.manual_count_id);
          if (matchingCount) {
            initialSelectedCounts[feature.properties.detected_count_id] = matchingCount.manual_count_id;
          }
        }
      });
      console.log('Initial selected counts:', initialSelectedCounts);
      setSelectedCounts(initialSelectedCounts);
    } catch (error) {
      console.error('Error fetching manual counts:', error);
    }
  };

  const handleManualCountChange = (detectedCountId, newCountId) => {
    setSelectedCounts(prevCounts => ({
      ...prevCounts,
      [detectedCountId]: newCountId
    }));
  };

  const handleSubmit = async (e) => {
    console.log('Submitting manual counts:', selectedCounts);
    setPregenerating(true);
    try {
      const response = await axios.post('/update_manual_counts', selectedCounts);
      if (response.status === 200) {
        console.log('Manual counts updated successfully');
        toast.success('Manual counts updated successfully');
      } else {
        console.error('Failed to update manual counts');
        toast.error('Failed to update manual counts');
      }
      // Delete current pregenerated assets
      await axios.post('/scans/delete_pregenerated', { orchard_code: orchard.code, scan_id: currentScanId });
      toast.success('Pregenerated assets deleted successfully');

      window.location.reload(); // Move the reload here
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast.error('An error occurred during the process');
    } finally {
      setPregenerating(false);
      setIsGroundTruthDialogOpen(false);
    }
  };


  const handleClose = () => {
    setIsGroundTruthDialogOpen(false);
  };

  function formatTimestamp(timestamp) {
    const date = dayjs(timestamp);
    const formattedDate = date.format('MMM Do h:mma');
    const daysAgo = date.fromNow();

    return `${formattedDate} (${daysAgo})`;
  }

  const copyCoordinates = async (coordinates) => {
    const lon = coordinates[0];
    const lat = coordinates[1];
    const coordinate = `${lat}, ${lon}`;
    console.log(coordinate);

    try {
      await navigator.clipboard.writeText(coordinate);
      toast('Section coordinates copied to clipboard', { icon: '📋' });
    } catch (error) {
      toast.error('Failed to copy coordinates');
    }
  };

  return (
    <Dialog open={isGroundTruthDialogOpen} onClose={handleClose} fullWidth PaperProps={{ style: { maxWidth: '1000px', }, }}>
      <DialogTitle>
        <Typography variant="h6" component="div">
          <b>Action Needed: </b>Set Ground Truths for Scan #{scanInfo.scan_number}, Block {blockInfo?.block_name}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Select the applicable ground truth count for each section. If a section does not have an applicable count close to detection date, leave as None.
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            padding: '12px',
            '& .MuiSvgIcon-root': {
              fontSize: '2rem',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <hr />
      <DialogContent>
        {pregenerating ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '200px' }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Recalibrating ...</Typography>


          </Box>
        ) : (
          <List>
            {sectionGeojson.features.filter((feature) => feature.properties.detected_count_id != null)
              .map((feature) => {
                const sectionCounts = manualCounts[feature.properties.section_code] || [];
                const currentValue = selectedCounts[feature.properties.detected_count_id] || '';

                return (
                  <ListItem key={feature.properties.section_code}>
                    <ListItemText
                      primary={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{`Section Code: ${orchard.section_code_prefix}${feature.properties.section_code}`}</span>
                          <Tooltip title="Click to copy coordinates" arrow placement="top">
                            <span>  
                              <FontAwesomeIcon
                                icon={faLocationDot}
                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                onClick={() => copyCoordinates(feature.geometry.coordinates)}
                              />
                            </span>
                          </Tooltip>
                        </div>
                      }
                      secondary={`${feature.properties.detections || 'No'} fruits detected, ${formatTimestamp(feature.properties.detection_timestamp) || 'N/A'}`}
                    />
                    {sectionCounts.length > 0 ? (
                      <FormControl sx={{ minWidth: 375 }}>
                        <InputLabel> Ground Truth Count</InputLabel>
                        <Select
                          value={currentValue}
                          onChange={(e) => handleManualCountChange(feature.properties.detected_count_id, e.target.value)}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {sectionCounts.map((countObj) => (
                            <MenuItem key={countObj.manual_count_id} value={countObj.manual_count_id}>
                              {countObj.manual_count} fruits, {formatTimestamp(countObj.manual_count_timestamp)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography sx={{ color: 'gray' }}>
                        No counts recorded for this section
                      </Typography>
                    )}
                  </ListItem>
                );
              })}
          </List>)}
      </DialogContent>
      <DialogActions>
        <div className='flex flex-col pr-8 pb-4'>
          <Button variant="contained" color="primary" onClick={(e) => handleSubmit(e)} disabled={pregenerating || !hasDetections}>
            Save & Refresh
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};