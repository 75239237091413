import React from 'react';
import diff_legend from '../../assets/diff_legend.png';

const StandardLegend = ({
    config,
    lowerBound,
    upperBound,
    setLowerBound,
    setUpperBound,
    applyPercentageToTarget,
    convertToBoundOfTarget,
    selectAll,
    stats,
    StyledTextField
}) => (
    <div className='flex flex-col items-center'>
        <div className='text-sm mb-1'>{config.caption} {config.targetVar === "uniTarg" && stats && stats.total_fruit_calibrated_estimated !== null && stats.total_fruit_calibrated_estimated !== 0 ? `(calibrated)` : ''}</div>
        <div className='relative w-full mb-1' style={{ height: '20px' }}>
            <img src={diff_legend} alt='color scale legend for plots' className='w-full h-full object-cover' />
        </div>
        <div className='flex justify-between w-full'>
            <StyledTextField
                type="number"
                value={applyPercentageToTarget(lowerBound)}
                onChange={(e) => convertToBoundOfTarget(e, setLowerBound)}
                onFocus={selectAll}
                InputProps={{
                    endAdornment: <span>{config.unit}</span>,
                }}
                size="small"
                style={{ width: '70px' }}
                variant="standard"
            />
            <div className='flex items-center'>
                {applyPercentageToTarget((parseFloat(lowerBound) + parseFloat(upperBound)) / 2)}
                <span className='ml-1'>{config.unit}</span>
            </div>
            <StyledTextField
                type="number"
                value={applyPercentageToTarget(upperBound)}
                onChange={(e) => convertToBoundOfTarget(e, setUpperBound)}
                onFocus={selectAll}
                InputProps={{
                    endAdornment: <span>{config.unit}</span>,
                }}
                size="small"
                style={{ width: '70px' }}
                variant="standard"
            />
        </div>
    </div>
);

export default StandardLegend;