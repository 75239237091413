import { faCrateApple, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import convert from 'convert-units';
import * as math from 'mathjs';
import { pricing_data_date } from '../constants/constants';
import { PackoutChart } from './components/PackoutChart';
import { VolumeChart } from './components/VolumeChart';

const InfoIcon = ({ tooltip }) => (
    <Tooltip title={tooltip} arrow>
        <span className="ml-1">
            <FontAwesomeIcon icon={faInfoCircle} size="sm" />
        </span>
    </Tooltip>
);

const formatVolume = (volume) => {
    const mm3 = Math.round(volume);
    const in3 = Number(convert(mm3).from('mm3').to('in3').toFixed(2));
    return { mm3, in3 };
};

const calculateDiameterFromVolume = (volume) => {
    const radius = math.cbrt((3 * volume) / (4 * Math.PI));
    const diameter = 2 * radius;
    return diameter;
};

export const HarvestEstimateDialog = ({
    open,
    onClose,
    dialogLoading,
    stats,
    scanInfo,
    scanDate,
    dateHarvested,
    daysDifference,
    treesPerAcre,
    density,
    avgFruitDiam,
    volumeGrowthRate,
    originalVolumeGrowthRate,
    linearRegression,
    estimatedVolume,
    volumeChartData,
    packoutDataLoading,
    filteredPackoutData,
    peakPackSize,
    totalBins,
    binsPerAcre,
    totalFruitWeight,
    blockRevenue,
    revenuePerTree,
    revenuePerAcre,
    adminUser,
    totalFruitHarvested,
    targetedPackSizes,
    setTargetedPackSizes,
    packoutData,
    total_fruit,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth="lg"
            fullWidth
            scroll="paper"
            PaperProps={{
                style: {
                    maxHeight: '100vh',
                    overflowY: 'hidden'
                }
            }}
        >
            <DialogTitle id="dialog-title">
                Harvest Estimation for Block {stats.block_name}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {dialogLoading ? (
                    <CircularProgress />
                ) : (
                    <Box>
                        <Grid container rowSpacing={2} columnSpacing={8} sx={{ mb: 2 }}>
                            {/* Top Left Quadrant */}
                            <Grid item xs={6}>
                                <Typography variant="h6" fontWeight="bold" sx={{ textAlign: 'center' }}>Measurements</Typography>
                                <Typography variant="body2" sx={{ textAlign: 'center', mb: 2, color: 'text.secondary' }}>
                                    Scan Date: {scanDate.format('MMMM D, YYYY')}
                                </Typography>
                                <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span>Trees per acre</span>
                                    <span>
                                        {treesPerAcre} trees/acre
                                        <InfoIcon 
                                            tooltip="If this number does not look right, it is possible that not all trees in the block have been scanned, and will thus underestimate harvest"
                                        />
                                    </span>
                                </Typography>
                                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Variety</span>
                                    <span>{scanInfo.variety_type !== null ? scanInfo.variety_type : 'N/A'}</span>
                                </Typography>
                                <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span>Fruit density</span>
                                    <span>
                                        {density !== null ? `${density.toFixed(2)} g/cm³` : 'N/A'}
                                        <InfoIcon 
                                            tooltip="Density measurement taken after cold storage and retail display. While not representative of harvest density, it reflects the fruit's condition during packing and distribution."
                                        />
                                    </span>
                                </Typography>
                                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Average fruit diameter</span>
                                    <span>{avgFruitDiam.toFixed(2)}mm ({convert(avgFruitDiam).from('mm').to('in').toFixed(2)}in)</span>
                                </Typography>
                                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Average fruit volume</span>
                                    <span>{formatVolume(stats.avg_fruit_vol).in3} in³</span>
                                </Typography>
                                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Volume growth rate</span>
                                    <span className={originalVolumeGrowthRate !== volumeGrowthRate ? 'text-yellow-500' : ''}>
                                        {volumeGrowthRate !== null ? `${Math.round(volumeGrowthRate)} mm³/day` : 'N/A'}
                                    </span>
                                </Typography>
                                {linearRegression && (
                                    <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>Growth rate reliability score (R²)</span>
                                        <span>
                                        {linearRegression ? linearRegression.r2.toFixed(2) : 'N/A'}
                                        <InfoIcon 
                                            tooltip="R² score closer to 1.00 yields better harvest estimates (ideally 0.95+)"
                                        />
                                        </span>
                                    </Typography>
                                )}
                            </Grid>

                            {/* Top Right Quadrant */}
                            <Grid item xs={6}>
                                <Typography variant="h6" fontWeight="bold" sx={{ textAlign: 'center' }}>Estimations</Typography>
                                <Typography variant="body2" sx={{ textAlign: 'center', mb: 2, color: 'text.secondary' }}>
                                    Harvest Date: {dateHarvested ? `${dateHarvested.format('MMMM D, YYYY')} (${daysDifference} day(s) after scan)` : 'Not set'}
                                </Typography>

                                <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span>Estimated fruit diameter</span>
                                    <span>
                                        {calculateDiameterFromVolume(estimatedVolume).toFixed(2)}mm ({convert(calculateDiameterFromVolume(estimatedVolume)).from('mm').to('in').toFixed(2)}in)
                                        <InfoIcon 
                                            tooltip="Derived from assuming a spherical estimated volume"
                                        />
                                    </span>
                                </Typography>
                                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Average fruit volume at harvest</span>
                                    <span>{formatVolume(estimatedVolume).in3} in³</span>
                                </Typography>

                                {!packoutDataLoading && stats.total_fruit_calibrated_estimated !== 0 && (
                                    <>
                                        <hr className="mt-2 mb-2"/>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>Total fruit</span>
                                            <span>
                                                {Math.round(totalFruitHarvested).toLocaleString()} fruits
                                                <InfoIcon 
                                                    tooltip="After cullage and omitted pack sizes"
                                                />
                                            </span>
                                        </Typography>
                                        {peakPackSize && (
                                            <Typography>
                                                Peak pack size <span style={{ float: 'right' }}>{peakPackSize || 'N/A'}</span>
                                            </Typography>
                                        )}
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Total bins</span>
                                            <span>{Number(totalBins.toFixed(2)).toLocaleString()} bins</span>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Bins per acre</span>
                                            <span>{binsPerAcre.toFixed(2)} bins/acre</span>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Average fruits per bin</span>
                                            <span>{Math.round(totalFruitHarvested / totalBins)} fruits/bin</span>
                                        </Typography>

                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Total fruit weight</span>
                                            <span>{Math.round(totalFruitWeight).toLocaleString()} lbs</span>
                                        </Typography>
                                        {adminUser && (
                                            blockRevenue && revenuePerTree && revenuePerAcre ? (
                                                <>
                                                    <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <span>Block revenue (admin)</span>
                                                        <span>
                                                            ${blockRevenue.toLocaleString()}
                                                            <InfoIcon 
                                                                tooltip={`Based on ${pricing_data_date} pricing data from WSTFA, for non-organic ${scanInfo.variety_type}; Applied per pack size when available`}
                                                            />
                                                        </span>
                                                    </Typography>
                                                    <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span>Revenue per tree (admin)</span>
                                                        <span>${revenuePerTree.toLocaleString()}</span>
                                                    </Typography>
                                                    <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span>Revenue per acre (admin)</span>
                                                        <span>${revenuePerAcre.toLocaleString()}</span>
                                                    </Typography>
                                                </>
                                            ) : (
                                                <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span className="text-gray-500">Revenue estimate (admin)</span>
                                                    <span className="text-gray-500">
                                                        No pricing data found for this variety
                                                        <InfoIcon 
                                                            tooltip={`No pricing data available in the WSTFA ${pricing_data_date} Report for variety: ${scanInfo.variety_type}`}
                                                        />
                                                    </span>
                                                </Typography>
                                            )
                                        )}
                                    </>
                                )}
                            </Grid>

                            {/* Bottom Left Quadrant */}
                            <Grid item xs={6}>
                                <Box sx={{ width: '100%', height: 400 }}>
                                    <VolumeChart data={volumeChartData} linearRegression={linearRegression} renderLineOfBestFit={originalVolumeGrowthRate === volumeGrowthRate && linearRegression}/>
                                </Box>
                            </Grid>

                            {/* Bottom Right Quadrant */}
                            <Grid item xs={6}>
                                <Box sx={{ width: '100%', height: 400 }}>
                                    {packoutDataLoading ? (
                                        <div className="flex flex-col justify-center items-center h-full">
                                            <p> Calculating Packout Estimate for {total_fruit} fruits</p>
                                            <br />
                                            <p> This may take a moment</p>
                                            <br />
                                            <FontAwesomeIcon icon={faCrateApple} size="5x" beatFade />
                                        </div>
                                    ) : packoutData ? (
                                        <>
                                            <PackoutChart data={filteredPackoutData} targetedPackSizes={targetedPackSizes} setTargetedPackSizes={setTargetedPackSizes}/>
                                        </>
                                    ) : null}
                                </Box>
                            </Grid>
                        </Grid>  
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};