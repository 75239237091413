import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { differenceInDays, parseISO, startOfDay } from 'date-fns';
import dayjs from 'dayjs';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import regression from 'regression';
import { applePricesPerPound, appleBoxPricesByPackSize, APPLE_BOX_STANDARD_LBS } from '../constants/constants';
import { Card } from './Cards';
import { FeedbackForm } from './components/FeedbackForm';
import { HarvestEstimateDialog } from './HarvestEstimateDialog';
import { useMapState } from '../context/MapStateContext';

export const HarvestEstimateCard = ({ stats, scanInfo, qualifyingBlocks, adminUser, blockInfo, treeData}) => {
    const { binsPerRow, setBinsPerRow } = useMapState();
    
    const scanDate = dayjs(parseISO(startOfDay(stats.timestamp).toISOString()));

    // Core Data States
    const [density, setDensity] = useState(null);
    const [treesPerAcre, setTreesPerAcre] = useState(0);
    const [linearRegression, setLinearRegression] = useState(null);
    const avgFruitDiam = stats.avg_fruit_diam !== null ? stats.avg_fruit_diam : 0;
    const total_fruit = stats.total_fruit_calibrated_estimated !== 0 
        ? numeral(Math.round(stats.total_fruit_calibrated_estimated)).format('0,0') 
        : numeral(Math.round(stats.total_fruit_detected)).format('0,0');
    
    const [totalFruitHarvested, setTotalFruitHarvested] = useState(0);

    // User Input States
    const [dateHarvested, setDateHarvested] = useState(null);
    const [minHarvestDate, setMinHarvestDate] = useState(null);
    const [volumeGrowthRate, setVolumeGrowthRate] = useState(0);
    const [originalVolumeGrowthRate, setOriginalVolumeGrowthRate] = useState(0);
    const [averageBinWeight, setAverageBinWeight] = useState(880);
    const [weightPerBox, setWeightPerBox] = useState(40);
    const [cullagePercent, setCullagePercent] = useState(5);
    const [isEditingGrowthRate, setIsEditingGrowthRate] = useState(false);

    // Calculation Output States
    const [estimatedVolume, setEstimatedVolume] = useState(null);
    const [totalFruitWeight, setTotalFruitWeight] = useState(0);
    const [binsPerAcre, setBinsPerAcre] = useState(0);
    const [totalBins, setTotalBins] = useState(0);
    const [blockRevenue, setBlockRevenue] = useState(null);
    const [revenuePerTree, setRevenuePerTree] = useState(null);
    const [revenuePerAcre, setRevenuePerAcre] = useState(null);
    const [targetedPackSizes, setTargetedPackSizes] = useState({});

    // Chart & Data Visualization States
    const [volumeChartData, setVolumeChartData] = useState(null);
    const [packoutData, setPackoutData] = useState(null);
    const [filteredPackoutData, setFilteredPackoutData] = useState(null);
    const [peakPackSize, setPeakPackSize] = useState(null);
    const [optimalDates, setOptimalDates] = useState([]);

    // UI Control States
    const [renderCard, setRenderCard] = useState(false);
    const [loading, setLoading] = useState(true);
    const [packoutDataLoading, setPackoutDataLoading] = useState(false);
    const [optimalDatesLoading, setOptimalDatesLoading] = useState(false);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [daysDifference, setDaysDifference] = useState(null);
    const [numQualityScans, setNumQualityScans] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    // Example growth rates (mm^3/day) for different apple varieties
    const hardcodedGrowthRates = {
        'Cosmic Crisp': 2680, // From 10/29/24 analysis of harvestable block data
        'Fuji': 1600,
        'Red Delicious': 1990,
        'Gala': 2800,
        'Honeycrisp': 2300,
        'Pink Lady': 2540,
        'Envy': 2100,
        'Ambrosia': 2760,
        'Granny Smith': 2110,
    };
    

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/util/get_harvest_estimate_info_for_scan', {
                params: { 
                    'block_id': scanInfo.block_id, 
                    'scan_id': String(scanInfo.scan_unique_id)
                }
            });


            setRenderCard(response.data.is_qualifying);

            if (response.data.is_qualifying) {
                //calculateRowWeights v0, in a state for demos
                //TODO: Calculate bins per row based on inputs from harvest estimate card, assert accuracy
                calculateRowWeights(treeData, averageBinWeight, 0);
            }
            else{
                setBinsPerRow({});
            }

            const qualifyingScans = response.data.qualifying_scans_count;
            setNumQualityScans(qualifyingScans);
            
            setDensity(response.data.density);
            setTreesPerAcre(response.data.trees_per_acre);

            const chartData = Object.entries(response.data.volume_data).map(([date, value]) => ({
                date: new Date(Date.parse(date + 'T00:00:00-08:00')).getTime(), 
                volume: parseFloat(value)
            })).sort((a, b) => a.date - b.date);

            setVolumeChartData(chartData);

            if (qualifyingScans >= 2) { //Only perform a linear regression with 2 or more data points
                const chartDataForRegression = chartData.map(({ date, volume }) => {
                    const daysSinceStart = differenceInDays(date, chartData[0].date);
                    return [daysSinceStart, volume];
                });
                const regressionResult = regression.linear(chartDataForRegression);
                setLinearRegression(regressionResult);
                setVolumeGrowthRate(Math.round(regressionResult.equation[0]));
                setOriginalVolumeGrowthRate(Math.round(regressionResult.equation[0]));
            }

            // Set the default harvest date to the day after the scan date
            setDateHarvested(dayjs()); // Today
            setMinHarvestDate(dayjs(scanDate.add(1, 'day'))); // Day after scan date

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (scanInfo) {
            fetchData();
        }
    }, [scanInfo]);

    // Reset targetedPackSizes whenever packoutData changes
    useEffect(() => {
        if (packoutData) {
            const initialSelectedSizes = Object.keys(packoutData).reduce((acc, size) => ({
                ...acc,
                [size]: true
            }), {});
            setTargetedPackSizes(initialSelectedSizes);
        }
    }, [packoutData]);

    // Handle filtering and calculations when targetedPackSizes changes
    useEffect(() => {
        if (packoutData && stats.total_fruit_calibrated_estimated && Object.keys(targetedPackSizes).length > 0) {
            let filteredPackoutData = {...packoutData}; // Shallow Copy
            let percentageSum = Object.entries(filteredPackoutData)
                .filter(([size]) => targetedPackSizes[size])
                .reduce((sum, [_, value]) => sum + value, 0);

            const scaleFactor = percentageSum > 0 ? 100 / percentageSum : 0;
            
            for (let [size, value] of Object.entries(filteredPackoutData)) {
                filteredPackoutData[size] = targetedPackSizes[size] ? scaleFactor * value : 0;
            }
            
            setFilteredPackoutData(filteredPackoutData);
            calculateBinsPerAcre(filteredPackoutData);
        }
    }, [packoutData, targetedPackSizes]);

    useEffect(() => {
        const fetchOptimalDates = async () => {
            if (!isEditingGrowthRate && volumeGrowthRate !== 0 && stats) {
                setOptimalDatesLoading(true);
                try {
                    const optimalDatesResponse = await axios.get('/util/get_optimal_packout_dates', {
                        params: { 'scan_id': stats.scan_id, 'growth_rate': volumeGrowthRate }
                    });
                    setOptimalDates(optimalDatesResponse.data);
                } catch (error) {
                    console.error("Error fetching optimal dates:", error);
                    setOptimalDates([]);
                } finally {
                    setOptimalDatesLoading(false);
                }
            }
        };
        fetchOptimalDates();
    }, [volumeGrowthRate, stats, isEditingGrowthRate]);

    const handleDateHarvestedChange = (date) => {
        setDateHarvested(date);
        setErrorMessage('');
    };

    const handleSubmit = async (harvestDate) => {
        if (!harvestDate) {
            setErrorMessage("Please enter a harvest date.");
            return;
        }
        if (harvestDate.isBefore(scanDate) || harvestDate.isSame(scanDate)) {
            setErrorMessage("Harvest date must be after the scan date.");
            return;
        }
        const daysDifference = harvestDate.diff(scanDate, 'day');
        setDaysDifference(daysDifference);
        setDialogLoading(true);
        setOpenDialog(true);
        const volumeOffset = volumeGrowthRate * daysDifference;
        const projectedVolume = stats.avg_fruit_vol + volumeOffset;
        setEstimatedVolume(projectedVolume);

        setDialogLoading(false);

        // Start loading packout data
        setPackoutDataLoading(true);
        const packout_response = await axios.get('/util/get_packout_data', { params: { scan_id: stats.scan_id, volume_offset: volumeOffset } });
        setPackoutData(packout_response.data); // Source of truth 
        setFilteredPackoutData(packout_response.data); // Rendered in PackoutChart
        setPackoutDataLoading(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setPeakPackSize(null);
    };

    const updateAverageBinWeight = (event) => {
        const value = event.target.value;
        if (value > 0) {
            setAverageBinWeight(value);
        } else {
            setAverageBinWeight('');
        }
    };

    const updateVolumeGrowthRate = (event) => {
        const value = event.target.value;
        if (value > 0) {
            setVolumeGrowthRate(Number(value));
        } else {
            setVolumeGrowthRate('');
        }
    };

    const updateWeightPerBox = (event) => {
        const value = event.target.value;
        if (value > 0) {
            setWeightPerBox(value);
        } else {
            setWeightPerBox('');
        }
    };

    const updateCullagePercent = (event) => {
        const value = event.target.value;
        if (value >= 0 && value <= 100) {
            setCullagePercent(value);
        } else {
            setCullagePercent('');
        }
    };

    const renderBlockLinks = () => {
        const protocol = window.location.protocol;
        const host = window.location.host;
        const rootUrl = `${protocol}//${host}/block/`;

        return (
            <>
            <hr className="mt-2"/>


                    <div className="mt-2">
                        <details className="cursor-pointer">
                    <summary className="text-blue-600 hover:text-blue-800">
                        View Qualifying Blocks
                    </summary>
                    {qualifyingBlocks ? (
                        Object.keys(qualifyingBlocks).length > 0 ? (
                            <ul className="mt-2 pl-4 list-disc">
                                {Object.entries(qualifyingBlocks).map(([blockId, blockName]) => (
                                <li key={blockId} className="mt-1">
                                    <a
                                        href={`${rootUrl}${encodeURIComponent(blockName)}`}
                                        className="text-blue-600 hover:text-blue-800 underline"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >
                                                {blockName}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="mt-2 bg-gray-100 text-gray-500 p-2 rounded">
                                No qualifying blocks found
                            </div>
                        )) : (
                            <div className="flex justify-center items-center h-10">
                            <Typography 
                                variant="body2" 
                                className="text-gray-500 text-center animate-pulse"
                            >
                                Calculating qualifying blocks...
                                </Typography>
                            </div>
                        )}
                    </details>
                    </div>
            
            </>
        );
    };


    const calculateRowWeights = (treeData, averageBinWeight, volumeOffset) => {
        // Initialize an object to store row totals
        const rowTotals = {};
        
        // Iterate through each tree feature
        treeData.features.forEach(feature => {
          const properties = feature.properties;
          const rowId = properties.row_id;

          const weightOffset = volumeOffset * (0.001) * 0.87 * (0.001); // mm³ -> cm³ -> g -> kg
          const avgWeight = properties.avg_weight ? properties.avg_weight + weightOffset : stats.avg_fruit_weight_kg + weightOffset ;

          const numBuds = properties.num_buds ? properties.num_buds : stats.fruit_per_tree_calibrated;
          // Calculate total weight for this tree
          const treeWeightLbs = avgWeight * numBuds * 2.20462; // Convert kg to lbs
          
          // Add to row total
          if (!rowTotals[rowId]) {
            rowTotals[rowId] = {
              totalWeight: 0,
              treeCount: 0,
              rowNumber: properties.row_num
            };
          }
          
          rowTotals[rowId].totalWeight += treeWeightLbs;
          rowTotals[rowId].treeCount += 1;
        });
        
        
        const sortedResults = Object.entries(rowTotals)
          .map(([rowId, data]) => ({
            rowId: parseInt(rowId),
            rowNumber: data.rowNumber,
            treeCount: data.treeCount,
            averageWeightPerTree: Math.round(data.totalWeight / data.treeCount),
            numBins: (data.totalWeight) / averageBinWeight 
          }))
          .sort((a, b) => a.rowNumber - b.rowNumber);
          
        const totalBinsAcrossRows = sortedResults.reduce((sum, row) => sum + row.numBins, 0);
        const totalWeightAcrossRows = sortedResults.reduce((sum, row) => sum + row.totalWeight, 0);
        // Convert array of row objects to map of row_id -> numBins
        setBinsPerRow(sortedResults.reduce((acc, row) => {
            acc[row.rowId] = Math.round(row.numBins);
            return acc;
        }, {}));

        return {
          rows: sortedResults,
          totalBins: Math.round(totalBinsAcrossRows),
          totalWeight: Math.round(totalWeightAcrossRows)
        };
      };

    // Given: Total Fruit, Fruit volume distribution (calculated from packout values), and average bin weight
    const calculateBinsPerAcre = (packoutValues) => {
        if (averageBinWeight === '' || averageBinWeight <= 0) {
            toast.error('Average bin weight must be greater than 0');
            return;
        }
        if (cullagePercent === '') {
            toast.error('Cullage percent must be provided');
            return;
        }
        if (stats.total_fruit_calibrated_estimated === 0) {
            toast.error('No fruit calibrations available');
            return;
        }
        if (averageBinWeight < weightPerBox) {
            toast.error('Average bin weight must be greater than weight per box');
            return;
        }
        // Check if all pack values are 0
        const allPackValuesZero = Object.values(packoutValues).every(value => value === 0);
        if (allPackValuesZero) {
            setTotalFruitHarvested(0);
            setTotalBins(0);
            setBinsPerAcre(0);
            setTotalFruitWeight(0);
            setBlockRevenue(0);
            setRevenuePerTree(0);
            setRevenuePerAcre(0);
            setPeakPackSize(null);
            return;
        }

        const peak = Object.entries(packoutValues).reduce((a, b) => a[1] > b[1] ? a : b);
        setPeakPackSize(peak[0]);

        // Calculate total excluded percentage
        const excludedSizesPercentage = Object.entries(packoutData)
            .filter(([size]) => !targetedPackSizes[size])
            .reduce((sum, [_, value]) => sum + value, 0) / 100;

        // Exclude packout sizes from the total fruit count
        const totalTargetedFruit = stats.total_fruit_calibrated_estimated * (1 - excludedSizesPercentage);

        // Apply cullage percentage to the total harvested fruit
        const totalFruitAfterCullage = totalTargetedFruit * (1 - cullagePercent / 100);

        setTotalFruitHarvested(totalFruitAfterCullage);

        // For each packout value percentage, multiply the value as a percentage by the totalFruitAfterCullage to get fruit counts per value
        const fruitCounts = Object.keys(packoutValues).reduce((obj, size) => {
            obj[size] = packoutValues[size] / 100 * totalFruitAfterCullage;
            return obj;
        }, {});

        // Get total fruit weight per key by multiplying the value for each key by (weightPerBox(40) / size)
        const fruitWeights = Object.keys(fruitCounts).reduce((obj, size) => {
            obj[size] = fruitCounts[size] * (weightPerBox / parseInt(size, 10));
            return obj;
        }, {});

        // Sum the total fruit weight per key to get the total fruit weight
        const totalFruitWeight = Object.values(fruitWeights).reduce((sum, weight) => {
            return sum + weight;
        });

        const totalBins = totalFruitWeight / averageBinWeight;
        const binsPerAcre = totalBins / stats.block_acreage;

        setTotalFruitWeight(totalFruitWeight);

        setBinsPerAcre(binsPerAcre);
        setTotalBins(totalBins);

        let revenue = 0;
        if (scanInfo.variety_type in appleBoxPricesByPackSize) {
            revenue = Object.entries(packoutValues).reduce((sum, [packSize, _]) => {
                return sum + fruitWeights[packSize] * appleBoxPricesByPackSize[scanInfo.variety_type][packSize] / APPLE_BOX_STANDARD_LBS;
            }, 0);
        } else if (scanInfo.variety_type in applePricesPerPound) {
            revenue = totalFruitWeight * applePricesPerPound[scanInfo.variety_type];
        }
        
        setBlockRevenue(Math.round(revenue));
        setRevenuePerTree((revenue / stats.total_trees_estimated).toFixed(2));
        setRevenuePerAcre(Math.round(revenue / blockInfo.acreage));

    };


    const renderOptimalDatesTable = () => {
        return (
            <div className="mt-2">
                <details className="cursor-pointer">
                    <summary className="text-blue-600 hover:text-blue-800">
                        Estimated Harvest Date by Packout Size
                    </summary>
                    
                    {optimalDatesLoading ? (
                        <div className="flex justify-center items-center h-10 mt-2">
                            <Typography 
                                variant="body2" 
                                className="text-gray-500 text-center animate-pulse"
                            >
                                Calculating the best harvest dates for each packout size...
                            </Typography>
                        </div>
                    ) : (
                        <>
                            <Typography variant="caption" className="text-gray-500 mt-1 block">
                                Predicts when each pack size will reach its maximum percentage of fruit in the block. Click an entry on the table to jump right to the harvest estimate on that date
                            </Typography>
                            <TableContainer component={Paper} className="mt-4">
                                <Table size="small" aria-label="optimal harvest dates table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="font-semibold">Harvest Date</TableCell>
                                            <TableCell align="right" className="font-semibold">Target Size</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {[...optimalDates]
                                            .sort((a, b) => {
                                                const dateComparison = new Date(a.date) - new Date(b.date);
                                                if (dateComparison === 0) {
                                                    return b.size - a.size;
                                                }
                                                return dateComparison;
                                            })
                                            .map(({ date, size }) => (
                                                <TableRow 
                                                    key={`${date}-${size}`}
                                                    hover
                                                    onClick={() => {
                                                        const selectedDate = dayjs(date);
                                                        setDateHarvested(selectedDate);
                                                        handleSubmit(selectedDate);
                                                    }}
                                                    className="cursor-pointer"
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {dayjs(date).format('MMM D, YYYY')}
                                                    </TableCell>
                                                    <TableCell align="right">{size}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </details>
            </div>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Card
                title={
                    <>
                        Estimate {scanInfo.variety_type && scanInfo.variety_type !== "OTHER" ? `${scanInfo.variety_type} ` : ''}Packout at Harvest
                        <sup className="text-red-500 text-xs font-bold ml-1">New!</sup>
                    </>
                }
                showButton
                defaultOpen={true}
                description={'Use volume growth rate to estimate fruit volume on a given harvest date'}
            >
                {loading ? (
                    <div className="flex justify-center items-center h-24">
                        <CircularProgress />
                    </div>
                ) : renderCard ? (
                    <div className="space-y-2">
                        <div className="flex items-center justify-between mt-2">
                            <div className="flex items-center">
                                <Typography variant="body1">
                                    Volume Growth Rate: 
                                </Typography>
                                {isEditingGrowthRate ? (
                                    <div className="flex items-center gap-2">
                                        <OutlinedInput
                                            type="number"
                                            size="small"
                                            value={volumeGrowthRate}
                                            onChange={updateVolumeGrowthRate}
                                            className="w-40"
                                            endAdornment={<InputAdornment position="end">mm³/day</InputAdornment>}
                                        />
                                        <Tooltip title="Example growth rates (based on 2024 data)">
                                            <IconButton
                                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                                size="small"
                                            >
                                                <ArrowDropDownIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {volumeGrowthRate !== originalVolumeGrowthRate && (
                                            <Tooltip title="Reset to original growth rate" arrow>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => setVolumeGrowthRate(originalVolumeGrowthRate)}
                                                    className="min-w-0 p-2"
                                                    sx={{ 
                                                        width: '32px', 
                                                        height: '32px',
                                                        minWidth: 'unset'
                                                    }}
                                                >
                                                    <RestartAltIcon fontSize="small" />
                                                </Button>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="Save current growth rate" arrow>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => setIsEditingGrowthRate(false)}
                                                className="min-w-0 p-2"
                                                sx={{ 
                                                    width: '32px', 
                                                    height: '32px',
                                                    minWidth: 'unset'
                                                }}
                                            >
                                                <CheckIcon fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={() => setAnchorEl(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            {Object.entries(hardcodedGrowthRates)
                                                .sort((a, b) => a[0].localeCompare(b[0]))
                                                .map(([variety, rate]) => (
                                                    <MenuItem 
                                                        key={variety} 
                                                        value={rate}
                                                        onClick={() => {
                                                            setVolumeGrowthRate(Number(rate));
                                                            setAnchorEl(null);
                                                        }}
                                                    >
                                                        {variety}: {rate} mm³/day
                                                    </MenuItem>
                                                ))}
                                        </Menu>
                                    </div>
                                ) : (
                                    <div className="flex items-center ml-2">
                                        <Typography variant="body1">
                                            {volumeGrowthRate} mm³/day
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            onClick={() => setIsEditingGrowthRate(true)}
                                            className="ml-2"
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        </div>

                        <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="body2" style={{ margin: '5px 0', fontSize: '14px', textAlign: 'center' }}>Estimated Harvest Date</Typography>
                                    <DatePicker
                                        value={dateHarvested}
                                        onChange={handleDateHarvestedChange}
                                        minDate={minHarvestDate}
                                        slotProps={{
                                            textField: {
                                                size: "small",
                                                error: !!errorMessage,
                                                helperText: errorMessage,
                                                sx: { 
                                                    width: '145px',
                                                    '& .MuiInputBase-root': {
                                                        height: '40px'
                                                    }
                                                }
                                            }
                                        }}
                                    />
                            </Grid>
                            {stats.total_fruit_calibrated_estimated !== 0 ? (
                                <>
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="body2" style={{ margin: '5px 0', fontSize: '14px', textAlign: 'center' }}>Historical Avg Bin Weight</Typography>
                                        <OutlinedInput
                                            type="number"
                                            size="small"
                                            style={{ width: '125px' }}
                                            value={averageBinWeight}
                                            onChange={updateAverageBinWeight}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                style: { textAlign: 'center' },
                                                min: 0
                                            }}
                                            endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="body2" style={{ margin: '5px 0', fontSize: '14px', textAlign: 'center' }}>Weight per box</Typography>
                                        <OutlinedInput
                                            type="number"
                                            size="small"
                                            style={{ width: '125px' }}
                                            value={weightPerBox}
                                            onChange={updateWeightPerBox}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                style: { textAlign: 'center' },
                                                min: 0
                                            }}
                                            endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="body2" style={{ margin: '5px 0', fontSize: '14px', textAlign: 'center' }}>Estimated In-field Cullage</Typography>
                                        <OutlinedInput
                                            type="number"
                                            size="small"
                                            style={{ width: '125px' }}
                                            value={cullagePercent}
                                            onChange={updateCullagePercent}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                style: { textAlign: 'center' },
                                                min: 0,
                                                max: 100
                                            }}
                                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <Box className="bg-yellow-50 border border-yellow-100 text-yellow-700 px-4 py-3 rounded text-center" role="alert">
                                        <Typography className="font-medium">
                                            Ground truth counts required to calculate bins / acre
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit(dateHarvested)}
                                sx={{ width: '200px' }}
                                disabled={!volumeGrowthRate || 
                                         !dateHarvested || 
                                         !averageBinWeight || 
                                         !weightPerBox || 
                                         cullagePercent === '' ||
                                         volumeGrowthRate === 0}
                            >
                                Calculate Estimate
                            </Button>
                        </Box>
               
                    </div>
                ) : (
                    <div>
                        <div className="bg-yellow-50 border border-yellow-100 text-yellow-700 px-4 py-3 rounded relative" role="alert">
                            <p className="font-medium">For the best estimate, we recommend 3+ previous fruit scans, on unique days, with 100,000+ detected fruit, after July 1st, 2024. </p>
                            <p className="font-medium">(At of the time of this scan, {numQualityScans} scan(s) fit this criteria in this block. Expand &apos;Scan Timeline&apos; for more context)</p>

                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => setRenderCard(true)}
                                sx={{ 
                                    textTransform: 'none',
                                    padding: '0',
                                    minWidth: 'auto',
                                    textDecoration: 'underline',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        textDecoration: 'underline',
                                    }
                                }}
                            >
                                Proceed Anyway
                            </Button>
                        </div>
                    </div>
                )}

                <HarvestEstimateDialog 
                    open={openDialog}
                    onClose={handleCloseDialog}
                    dialogLoading={dialogLoading}
                    stats={stats}
                    scanInfo={scanInfo}
                    scanDate={scanDate}
                    dateHarvested={dateHarvested}
                    daysDifference={daysDifference}
                    treesPerAcre={treesPerAcre}
                    density={density}
                    avgFruitDiam={avgFruitDiam}
                    volumeGrowthRate={volumeGrowthRate}
                    originalVolumeGrowthRate={originalVolumeGrowthRate}
                    linearRegression={linearRegression}
                    estimatedVolume={estimatedVolume}
                    volumeChartData={volumeChartData}
                    packoutDataLoading={packoutDataLoading}
                    filteredPackoutData={filteredPackoutData}
                    peakPackSize={peakPackSize}
                    totalBins={totalBins}
                    binsPerAcre={binsPerAcre}
                    totalFruitWeight={totalFruitWeight}
                    blockRevenue={blockRevenue}
                    revenuePerTree={revenuePerTree}
                    revenuePerAcre={revenuePerAcre}
                    adminUser={adminUser}
                    totalFruitHarvested={totalFruitHarvested}
                    targetedPackSizes={targetedPackSizes}
                    setTargetedPackSizes={setTargetedPackSizes}
                    packoutData={packoutData}
                    total_fruit={total_fruit}
                />

                {renderCard && volumeGrowthRate > 0 && (<> 
                    <hr className="mt-2"/>
                    {renderOptimalDatesTable()}
                </>)}

                {renderBlockLinks()}
                
                <hr className="mt-2"/>
                <details className="cursor-pointer mt-2">
                    <summary className="text-blue-600 hover:text-blue-800">
                        Tell us what you think
                    </summary>
                    <div className="mt-2">
                        <FeedbackForm truthfulScanName={scanInfo.scan_name} />
                    </div>
                </details>
            </Card>
        </LocalizationProvider>
    );
};

