import { useState } from 'react';
import { TextField, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-hot-toast';

export const ManagerZoneInfo = ({ previewGeoJson }) => {

    let rowNumZoneCount = {};
    let colors = []

    let colorNameMap = {
        "cornflowerblue": "Blue",
        "limegreen": "Green",
        "red": "Red",
    };

    if (previewGeoJson && previewGeoJson.features && previewGeoJson.features.length > 0) {
        for (let i = 0; i < previewGeoJson.features.length; i++) {
            let feature = previewGeoJson.features[i];
            let color = feature.properties.color;
            let rowNum = feature.properties.row_num;
            if (rowNumZoneCount[rowNum] == null) {
                rowNumZoneCount[rowNum] = {};
            }
            if (rowNumZoneCount[rowNum][color] == null) {
                rowNumZoneCount[rowNum][color] = 0;
            }
            if (rowNumZoneCount[rowNum].total == null) {
                rowNumZoneCount[rowNum].total = 0;
            }
            rowNumZoneCount[rowNum][color] += 1;
            rowNumZoneCount[rowNum].total += 1;
            if (!colors.includes(color)) {
                colors.push(color);
            }
        }
    }

    return (
        <div className="flex flex-col items-end w-full">
            <table>
            <thead className="bg-gray-100">
                <tr>
                    <th className="border px-4 py-2">Row #</th>
                    {colors.map((color) => (
                    <th className="border px-4 py-2">Number of {colorNameMap[color]} Trees
                    <div style={{clear: "both", backgroundColor: color, height: "20px"}}>
                        
                    </div>
                    </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {Object.keys(rowNumZoneCount).map((rowNum) => (
                    <tr style={rowNum % 2 ? {backgroundColor: "lightgrey"} : {}} className="border px-4 py-2">
                        <td className="border px-4 py-2">Row {rowNum}
                            <div style={{clear: "both"}}>
                                {rowNumZoneCount[rowNum].total} trees
                            </div>
                        </td>
                        {colors.map((color) => (
                            <td className="border px-4 py-2">
                                {((rowNumZoneCount[rowNum][color] || 0) / rowNumZoneCount[rowNum].total * 100).toLocaleString(undefined, {maximumFractionDigits:0})}%
                                <div style={{clear: "both"}}></div>
                                {rowNumZoneCount[rowNum][color] || 0} trees
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            </table>

        </div>
    );
};
