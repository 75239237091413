import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';


const renderDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', { 
        month: '2-digit', 
        day: '2-digit' 
    });
};

export const TimelineCard = ({ item, isExpanded, selected, onCardClick }) => {
    let bgColor, hoverColor;

    switch (item.entity_type) {
        case 'fruits':
            bgColor = 'bg-blue-200';
            hoverColor = 'hover:bg-blue-300';
            break;
        default:
            bgColor = 'bg-gray-200';
            hoverColor = 'hover:bg-gray-300';
    }

    return (
        <button
            onClick={onCardClick}
            className={`flex justify-between border border-solid border-slate-400 shadow-md items-center p-2 rounded-md mb-4 w-full focus:outline-none 
        ${selected && !isExpanded ? 'hover:scale-105 transition-all duration-300 hover-card ' : 'hover-card hover:scale-105 transition-all duration-300'}
        ${selected && isExpanded ? 'scale-105 transition-all duration-300 hover-card ' : 'hover-card hover:scale-105 transition-all duration-300'}
        ${bgColor} ${hoverColor}`}
        >
            <div className="w-1/3 text-left">
                {item.scan_name && <p className="text-sm" data-testid="top-card-scan-name"> {item.scan_name} </p>}
                {item.time && <p className="text-sm"> {renderDate(item.time)} </p>}
                {item.percent_trees >= 0 && <p className="text-sm">{item.percent_trees}% Coverage</p>}
            </div>

            <div className="w-1/3 text-center">
                {item.variety_type && <p className='text-sm'>{item.variety_type}</p>}
            </div>

            <div className="w-1/3 flex justify-end items-center">
                {item.entity_type && <>
                    <p className="text-sm">{item.stage_type ? item.stage_type : item.entity_type.charAt(0).toUpperCase() + item.entity_type.slice(1)}</p>
                    <FontAwesomeIcon className="ml-1" icon={'fa-solid fa-arrow-right'} />
                </>}
            </div>
        </button>
    );
};

export const StackedCard = ({ index, isExpanded, children, onCardClick, selected }) => {
    const translateY = isExpanded ? index * 64 : selected ? 0 : -6 * (index + 1.1);
    const transform = isExpanded ? `translateY(${translateY}px)` : `translateY(${translateY}px) scale(1)`;

    const style = {
        transform,
        zIndex: selected ? 1 : isExpanded ? 0 : -index,
    };

    return (
        <div
            onClick={onCardClick}
            className={`w-full px-4 absolute cursor-pointer ${isExpanded ? '' : 'hover-card transition-all ease-in-out'} duration-1000`}
            style={style}
        >
            {isExpanded && (
                <>
                    <div className="absolute left-1/2 top-5 w-0.5 h-2 bg-gray-300" style={{ transform: 'translateX(-50%)' }}></div>
                    {index === 0 && (
                        <>
                            <p className="text-center text-xs font-bold absolute left-1/2 w-full" style={{ top: '0px', transform: 'translateX(-50%)' }}>
                                Now
                            </p>
                            <hr className="mt-1 border-t border-gray-300 absolute left-1/2 w-1/6" style={{ top: '16px', transform: 'translateX(-50%)' }} />
                        </>
                    )}
                </>
            )}
            {children}
        </div>
    );
};


export const BlockTimeline = ({
    handleIconClick,
    timelineData,
    currentScanId,
    isExpanded,
    setIsExpanded,
}) => {

    // Sort timelineData by timestamp parsed from scan_name in ascending order
       const sortedTimelineData = timelineData.sort((a, b) => {
        const timeComparison = new Date(b.time) - new Date(a.time);
        
        // If timestamps are the same, choose the fruit scan first
        if (timeComparison === 0) {
            if (a.entity_type === 'fruits' && b.entity_type === 'trees') {
                return -1; 
            } else if (a.entity_type === 'trees' && b.entity_type === 'fruits') {
                return 1; 
            }
        }
        
        return timeComparison;
    });

    const onCardClick = (scanName, scanId) => {
        if (!isExpanded) {
            setTimeout(() => {
                setIsExpanded(true);
            }, 500); // match the duration of the transition
        } else {
            handleIconClick(scanName, scanId);
        }
    };

    const renderTimelineItems = () => {
        const selectedCardIndex = sortedTimelineData.findIndex(
            (item) => item.scan_id === currentScanId
        );

        let sortedWithSelectedOnTop = isExpanded
            ? sortedTimelineData
            : [
                sortedTimelineData[selectedCardIndex],
                ...sortedTimelineData.slice(0, selectedCardIndex),
                ...sortedTimelineData.slice(selectedCardIndex + 1),
            ];

        sortedWithSelectedOnTop = sortedWithSelectedOnTop.filter(function (element) {
            return element !== undefined;
        });

        return (
            <>
                {sortedWithSelectedOnTop.map((item, index) => (
                    <StackedCard
                        key={item.scan_id}
                        index={index}
                        isExpanded={isExpanded}
                        onCardClick={() => onCardClick(item.raw_scan_name, item.scan_id)}
                        selected={item.scan_id === currentScanId}
                    >
                        <TimelineCard
                            item={item}
                            isExpanded={isExpanded}
                            selected={item.scan_id === currentScanId}
                            onCardClick={() => onCardClick(item.raw_scan_name, item.scan_id)}
                        />
                    </StackedCard>
                ))}
            </>
        );
    };

    return (
        <>
            <div
                className={`overflow-y-auto h-full pt-2 relative scrollbar-thin ${isExpanded ? 'scrollbar-visible' : ''} scrollbar-thumb-gray-400 scrollbar-track-gray-200`}
                style={{
                    minHeight: "100px",
                }}
            >
                {renderTimelineItems()}
            </div>

            {isExpanded && (
                <div className="absolute bottom-0 left-0 right-0 flex justify-center mb-2 cursor-pointer" onClick={() => setIsExpanded(false)}>
                    <FontAwesomeIcon className="w-6 h-6 text-gray-500 hover:text-gray-700" icon={faChevronUp} />
                </div>
            )}

        </>
    );

};