import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

const HueLegend = ({
    treeData,
    stats,
    lowerBound,
    upperBound,
    setLowerBound,
    setUpperBound,
    config,
}) => {
    const canvasSize = 200;
    const canvasRef = useRef(null);
    const isDragging = useRef(false);
    const activeHandle = useRef(null);

    // Add new state for angles
    const [lowerBoundAngle, setLowerBoundAngle] = useState(lowerBound * 2);
    const [upperBoundAngle, setUpperBoundAngle] = useState(upperBound * 2);

    // Convert angle to hue (0-180)
    const angleToHue = (angle) => {
        // Normalize angle to 0-360 range
        angle = (angle + 360) % 360;
        // Convert to 0-180 range
        return angle / 2;
    };

    // Convert hue to angle (0-360)
    const hueToAngle = (hue) => {
        return hue * 2;
    };

    // Convert mouse position to angle
    const getAngle = (x, y, centerX, centerY) => {
        const deltaX = x - centerX;
        const deltaY = y - centerY;
        let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
        // Convert from [-180, 180] to [0, 360]
        return (angle + 360) % 360;
    };

    const drawCircularSlider = () => {
        const devicePixelRatio = window.devicePixelRatio;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const width = canvas.width / devicePixelRatio;
        const height = canvas.height / devicePixelRatio;
        const centerX = width / 2;
        const centerY = height / 2;
        const radius = Math.min(width, height) / 2 - 30;

        ctx.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);

        // Clear canvas
        ctx.clearRect(0, 0, width, height);

        // Draw color wheel
        for (let angle = 0; angle < 360; angle++) {
            const startAngle = (angle - 1) * Math.PI / 180;
            const endAngle = angle * Math.PI / 180;
            
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius, startAngle, endAngle);
            ctx.closePath();
            
            ctx.fillStyle = `hsl(${angle}, 100%, 50%)`;
            ctx.fill();
        }

        // Draw highlighted arc for selected range
        ctx.beginPath();
        const startAngle = hueToAngle(lowerBound) * Math.PI / 180;
        const endAngle = hueToAngle(upperBound) * Math.PI / 180;
        
        // Draw outer highlight
        ctx.arc(centerX, centerY, radius + 3, startAngle, endAngle);
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = 6;
        ctx.stroke();
        
        // Draw inner white highlight
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius + 3, startAngle, endAngle);
        ctx.strokeStyle = '#ffffff';
        ctx.lineWidth = 4;
        ctx.stroke();

        // Draw connecting lines from center to handles
        [lowerBound, upperBound].forEach((value) => {
            const angle = hueToAngle(value) * Math.PI / 180;
            const x = centerX + Math.cos(angle) * radius;
            const y = centerY + Math.sin(angle) * radius;
            
            // Draw line
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.lineTo(x, y);
            ctx.strokeStyle = '#ffffff';
            ctx.lineWidth = 2;
            ctx.stroke();
            
            // Draw black outline for the line
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.lineTo(x, y);
            ctx.strokeStyle = '#000000';
            ctx.lineWidth = 3;
            ctx.globalCompositeOperation = 'destination-over';
            ctx.stroke();
            ctx.globalCompositeOperation = 'source-over';
        });

        // Draw handles
        [lowerBound, upperBound].forEach((value) => {
            const angle = hueToAngle(value) * Math.PI / 180;
            const x = centerX + Math.cos(angle) * radius;
            const y = centerY + Math.sin(angle) * radius;
            
            ctx.beginPath();
            ctx.arc(x, y, 8, 0, 2 * Math.PI);
            ctx.fillStyle = `hsl(${value * 2}, 100%, 50%)`;
            ctx.fill();
            ctx.strokeStyle = '#fff';
            ctx.lineWidth = 2;
            ctx.stroke();
        });

        // Add degree annotations
        ctx.font = '12px Arial';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        
        // 0° (right)
        ctx.fillText('0°', centerX + radius + 16, centerY);
        
        // 90° (bottom)
        ctx.fillText('90°', centerX, centerY + radius + 16);
        
        // 180° (left)
        ctx.fillText('180°', centerX - radius - 16, centerY);
        
        // 270° (top)
        ctx.fillText('270°', centerX, centerY - radius - 16);
    };

    useEffect(() => {
                // Update angles whenever bounds change
        setLowerBoundAngle(lowerBound * 2);
        setUpperBoundAngle(upperBound * 2);

        // Problem
        if (lowerBound > upperBound) {
            setUpperBound(upperBound + 180);
        }
        else if (upperBound - lowerBound > 180) {
            setUpperBound(upperBound - 180);
        }
        
        drawCircularSlider();
        

    }, [lowerBound, upperBound, stats]);

    const handleMouseDown = (e) => {
        const devicePixelRatio = window.devicePixelRatio;

        const canvas = canvasRef.current;

        const width = canvas.width / devicePixelRatio;
        const height = canvas.height / devicePixelRatio;
        
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const centerX = width / 2;
        const centerY = height / 2;
        
        const clickAngle = getAngle(x, y, centerX, centerY);
        const clickHue = angleToHue(clickAngle);
        
        // Calculate differences considering the circular nature
        const lowerDiff = Math.min(
            Math.abs(clickHue - lowerBound),
            Math.abs(clickHue - lowerBound + 180),
            Math.abs(clickHue - lowerBound - 180)
        );
        const upperDiff = Math.min(
            Math.abs(clickHue - upperBound),
            Math.abs(clickHue - upperBound + 180),
            Math.abs(clickHue - upperBound - 180)
        );
        
        isDragging.current = true;
        activeHandle.current = lowerDiff < upperDiff ? 'lower' : 'upper';
    };

    const handleMouseMove = (e) => {
        const devicePixelRatio = window.devicePixelRatio;

        const canvas = canvasRef.current;

        const width = canvas.width / devicePixelRatio;
        const height = canvas.height / devicePixelRatio;

        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const centerX = width / 2;
        const centerY = height / 2;

        // Check if cursor is near any handle
        const radius = Math.min(width, height) / 2 - 30;
        const isNearHandle = [lowerBound, upperBound].some(value => {
            const angle = hueToAngle(value) * Math.PI / 180;
            const handleX = centerX + Math.cos(angle) * radius;
            const handleY = centerY + Math.sin(angle) * radius;
            
            const distance = Math.sqrt(
                Math.pow(x - handleX, 2) + Math.pow(y - handleY, 2)
            );
            return distance <= 12; // Slightly larger than handle radius for better UX
        });

        canvas.style.cursor = isNearHandle ? 'grab' : 'default';
        if (isDragging.current) {
            canvas.style.cursor = 'grabbing';
        }

        if (!isDragging.current) return;

        const angle = getAngle(x, y, centerX, centerY);
        const hue = Math.round(angleToHue(angle));
            
        if (activeHandle.current === 'lower') {
            setLowerBound(hue);
        } else {
            setUpperBound(hue);
        }
    };

    const handleMouseUp = () => {
        isDragging.current = false;
        activeHandle.current = null;
    };

    // Add function to calculate percentage of trees within bounds
    const calculateTreePercentage = () => {
        if (!treeData || !treeData.features || treeData.features.length === 0) return '0 (0%)';
        
        const treesInRange = treeData.features.filter(tree => {
            const hue = tree.properties[config.feature];
            
            return (
                (hue >= lowerBound && hue <= upperBound) ||
                (hue + 180 >= lowerBound && hue + 180 <= upperBound)
            );
        });

        const percentage = (treesInRange.length / treeData.features.length) * 100;
        return `${treesInRange.length} (${percentage.toFixed(1)}%)`;
    };

    // Simplified handler for button
    const handleViewAllTrees = () => {
        setLowerBound((stats[config.stat] + config.bounds?.[0] + 360) % 180);
        setUpperBound((stats[config.stat] + config.bounds?.[1] + 360) % 180);
    };

    const devicePixelRatio = window.devicePixelRatio;

    return (
        <div className='flex flex-row items-center justify-start gap-8 w-full'>
            <div className='flex flex-col items-center'>
                <Box className={`relative w-[${canvasSize}px] h-[${canvasSize}px]`}>
                    <canvas
                        ref={canvasRef}
                        width={canvasSize * devicePixelRatio}
                        height={canvasSize * devicePixelRatio}
                        style={{width: `${canvasSize}px`, height: `${canvasSize}px`}}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                    />
                </Box>
            </div>

            <div className='flex flex-col items-start gap-4'>
                <div className='flex flex-col gap-1'>
                    <Typography variant='h6'>{config.caption}</Typography>
                    <Typography variant='caption' color="text.secondary">
                        Click & drag the color bounds to edit the hue range
                    </Typography>
                </div>
                <div className='text-base'>
                    <div className='font-medium'>Hue Range: {Math.round(lowerBoundAngle % 360)}° - {Math.round(upperBoundAngle % 360)}°</div>
                    <div className='font-medium'>Trees: {calculateTreePercentage()}</div>
                </div>
                <Button
                    variant="contained"
                    onClick={handleViewAllTrees}
                    className="normal-case"
                >
                    Reset
                </Button>
            </div>
        </div>
    );
};

export default HueLegend;
