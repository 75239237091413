import { faCircleLocationArrow, faMapLocationDot, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { ViewMode } from '../common/types';
import { getTerminology } from '../i18n';
import { OrchardCard } from './Cards';
import { SeasonSelector } from './SeasonSelector';

const BlocksList = ({ blockScanMap, setTreeViewLat, setTreeViewLong, setViewMode, setBlockName, searchTerm, setInitialLocation }) => {
    const [editingBlock, setEditingBlock] = useState(null);
    const [editValue, setEditValue] = useState('');

    const handleClick = (block_name, center_lat, center_lon) => {
        setTreeViewLat(center_lat);
        setTreeViewLong(center_lon);
        setViewMode(ViewMode.Block);
        setBlockName(block_name);
    }

    const handleIconClick = (center_lat, center_lon) => {
        if (center_lat && center_lon) {
            setInitialLocation({ lat: center_lat, long: center_lon });
        }
        else {
            console.log('No center_lat or center_lon');
        }
    }

    // TODO: Block names should update automatically, or tell user to refresh to see changes
    // TODO: Have toast show errors and success messages
    const handleEditSubmit = async (blockId, newName) => {
        try {
            const response = await axios.post('/block/update_name', {
                block_id: blockId,
                new_name: newName
            });
            
            if (response.status === 200) {
                // Reset editing state
                setEditingBlock(null);
                setEditValue('');
                // You might need to refresh the block data here
            }
        } catch (error) {
            console.error('Failed to update block name:', error);
            // Handle error (show toast/alert)
        }
    };

    return (
        <div className="overflow-y-auto h-full flex-grow min-h-0 scrollbar-thin scrollbar-visible scrollbar-thumb-gray-400 scrollbar-track-gray-200" data-testid="block-list-container">
            {Object.entries(blockScanMap)
                .filter(([blockName, blockData]) => 
                    blockName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    blockData.scans.some(scan => scan.toString().includes(searchTerm))
                )
                .sort(([, a], [, b]) => {
                    if (a.last_scan_timestamp && b.last_scan_timestamp) {
                        return new Date(b.last_scan_timestamp) - new Date(a.last_scan_timestamp);
                    }
                    if (a.last_scan_timestamp) return -1;
                    if (b.last_scan_timestamp) return 1;
                    return 0;
                })
                .map(([blockName, blockData], index) => {
                    const { center_lat, center_lon, scans, last_scan_timestamp, last_scan_type, block_id } = blockData;
                    const mostRecentScan = scans[0];
                    
                    return (
                        <Tooltip
                            key={blockName} 
                            title={scans.length === 0 ? 'No scans in block' : scans.join(', ')}
                            placement="top-end"
                            enterDelay={500}
                        >
                            <div
                                className="flex border my-4 bg-gray-200 hover:bg-gray-300 border-gray-400 p-2 rounded-lg cursor-pointer whitespace-nowrap overflow-hidden group"
                                onClick={() => handleClick(blockName, center_lat, center_lon)} 
                                data-testid={`block-item-${index}`}
                            >
                                <div className="flex flex-col w-full">
                                    <div className="flex justify-between">
                                        {editingBlock === blockName ? (
                                            <input
                                                type="text"
                                                value={editValue}
                                                onChange={(e) => setEditValue(e.target.value)}
                                                onClick={(e) => e.stopPropagation()}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleEditSubmit(block_id, editValue);
                                                    } else if (e.key === 'Escape') {
                                                        setEditingBlock(null);
                                                        setEditValue('');
                                                    }
                                                }}
                                                className="mr-2 px-2 rounded border border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                autoFocus
                                            />
                                        ) : (
                                            <span className="text-l mr-10">{blockName}</span>
                                        )}
                                        <span className="text-l flex overflow-x-hidden">
                                            {scans.length === 0 ? (
                                                <span className="text-gray-600">No scans in block</span>
                                            ) : (
                                                <span className="overflow-ellipsis overflow-hidden">
                                                    Total Scans: {scans.length}
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        {scans.length > 0 && (
                                            <span className="text-sm text-gray-600" data-testid={`last-scan-timestamp-${index}`}>
                                                {last_scan_timestamp && `Last Scan: ${mostRecentScan} | ${last_scan_timestamp} | ${last_scan_type ? last_scan_type : getTerminology('Trees')}`}
                                            </span>
                                        )}
                                        <div className="flex gap-2 invisible group-hover:visible">
                                            {/* <FontAwesomeIcon 
                                                icon={faPenToSquare}
                                                className="mt-1 cursor-pointer hover:text-blue-500 z-10"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setEditingBlock(blockName);
                                                    setEditValue(blockName);
                                                }}
                                                title="Edit block name"
                                            /> */}
                                            <FontAwesomeIcon 
                                                icon={faCircleLocationArrow} 
                                                className="mt-1 cursor-pointer hover:text-blue-500 z-10"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleIconClick(center_lat, center_lon);
                                                }}
                                                title="Fly to block location"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    );
                })}
        </div>
    );
};


export const OrchardView = ({ viewMode, blockScanMap, orchard, setTreeViewLat, setTreeViewLong, setViewMode, setBlockName, setInitialLocation, currentSeason, setCurrentSeason, availableSeasons }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSetSearchTerm = useCallback(
        debounce((value) => setSearchTerm(value), 300),
        []
    );

    const handleSearchChange = (event) => {
        debouncedSetSearchTerm(event.target.value);
    };

    return (
        <OrchardCard viewMode={viewMode} title={orchard.name} className="h-full">
            <div className='flex flex-col min-h-full' data-testid="orchard-view-card">
                <span className='flex flex-row mt-1'>
                    <FontAwesomeIcon size='lg' icon={'fa-solid fa-house'} fixedWidth /> {'  '}
                    <span className={'text-l pl-2'}>{orchard.address}</span>
                </span>
                <span className='flex flex-row mt-1'>
                    <FontAwesomeIcon size='lg' icon={faMapLocationDot} fixedWidth /> {'  '}
                    <span className={'text-l pl-2'}>{orchard.total_acres} Acres</span>
                </span>
                <hr className='border-gray-300 my-1' />
                <SeasonSelector 
                    currentSeason={currentSeason} 
                    setCurrentSeason={setCurrentSeason} 
                    availableSeasons={availableSeasons} 
                />
                <span>
                    <a href="https://orchardrobotics.talentlms.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        Operator Certification & User Guide
                    </a>
                </span>
        

                <hr className='border-gray-300 my-2' />
                <h1 className='text-left text-xl font-bold font-lato'> Blocks</h1>
                <p className='pb-1 text-xs text-slate-500 pt-1 text-left text-gray-500'>Click on an entry below or click a block in the map to view scans. Hover to view all scan names.</p>
                
                <div className="relative">
                    <FontAwesomeIcon 
                        icon={faSearch} 
                        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    />
                    <input
                        type="text"
                        placeholder="Search Block Name or any Scan #"
                        onChange={handleSearchChange}
                        className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                </div>
                
                <div className='flex-grow overflow-hidden pb-6'>
                    {blockScanMap && (
                        <BlocksList
                            blockScanMap={blockScanMap}
                            setTreeViewLat={setTreeViewLat}
                            setTreeViewLong={setTreeViewLong}
                            setViewMode={setViewMode}
                            setBlockName={setBlockName}
                            searchTerm={searchTerm}
                            setInitialLocation={setInitialLocation}
                        />
                    )}
                </div>
            </div>
        </OrchardCard>
    );
}
