import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { EntityType } from '../common/types';
import { Card } from './Cards';
import { ExportCard } from './ExportCard';
import { getTerminology } from '../i18n';
export const DownloadCard = ({ plotType, entityType, handleDownloadChange, downloadFormat, mapInstance, blockName, treeData, stats, scanInfo, currentScanId }) => {

    const getActionOptions = () => {
        // TODO: remove type 'Report'
        const baseOptions = [
            // { value: 'Map_Image', label: 'Map Image' },
            { value: 'pertree_CSV', label: 'Per tree Spreadsheet' },
            { value: 'treebyrow_CSV', label: 'Tree by Row Spreadsheet' },
            { value: 'missingtreebyrow_CSV', label: 'Missing Tree by Row Spreadsheet' },
            { value: 'smoothed', label: 'Spatial Region Heatmap' },
            { value: 'discrete', label: 'Zone Heatmap' },
            { value: 'share_geojson', label: 'Shareable Link to Map' },
        ];

        return entityType === EntityType.Fruits
            ? [...baseOptions, { value: 'fruit_csv', label: 'Per fruit Spreadsheet' }]
            : baseOptions;
    };

    return (
        <Card title="Export Data" showButton defaultOpen={true} description="Select a file format to download or export data in. Map exports will reflect the current map view">
            <Box sx={{ minWidth: 100 }} className='py-4'>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Download Format</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={downloadFormat}
                        label="Download Format"
                        onChange={handleDownloadChange}
                    >
                        {getActionOptions().map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {getTerminology(option.label)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <ExportCard
                plotType={plotType}
                entityType={entityType}
                mapInstance={mapInstance}
                blockName={blockName}
                downloadFormat={downloadFormat}
                treeData={treeData}
                stats={stats}
                scanInfo={scanInfo}
                currentScanId={currentScanId}
            />
        </Card>
    );
}