import axios from 'axios';
import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import ALL from '../assets/ALLFruitscope.png';
import CHI from '../assets/CHIFruitscope.png';
import MadeByOrchard from '../assets/MadeByOrchard.png';
import NWF from '../assets/NWFFruitscope.png';
import SAG from '../assets/SAGFruitscope.png';
import STE from '../assets/STEFruitscope.png';
import SVO from '../assets/SVOFruitscope.png';
import ZIR from '../assets/ZIRFruitscope.png';
import loading from '../assets/loading.gif';
import { Block, BlockScanMap, Coordinate, EntityType, FruitType, Orchard, ScanInfo, StageType, Statistics, ViewMode } from '../common/types';
import { MapBox } from '../map/MapBox';
import { Sidebar } from '../sidebar/Sidebar';

// TODO: replace any with proper types
interface HomeProps {
  orchard: Orchard | null;
  orchardCode: string;
  blockData: any;
  initialLoad: number;
  dbName: React.MutableRefObject<string>;
  plotType: string | null;
  downloadFormat: string;
  setDownloadFormat: React.Dispatch<React.SetStateAction<string>>;
  setPlotType: React.Dispatch<React.SetStateAction<string | null>>;
  chartType: string;
  setChartType: React.Dispatch<React.SetStateAction<string>>;
  mapInstance: React.MutableRefObject<mapboxgl.Map | null>;
  scanLoading: boolean;
  setScanLoading: React.Dispatch<React.SetStateAction<boolean>>;
  blockScanMap: BlockScanMap;
  entity: EntityType;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  progress: number;
  stats: Statistics | null;
  setCurrentScanId: React.Dispatch<React.SetStateAction<number | null>>;
  blockName: string | null;
  setBlockName: React.Dispatch<React.SetStateAction<string | null>>;
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  currentScanId: number | null;
  addToSidebar: boolean;
  polygonStats: Statistics | null;
  stageType: StageType | null;
  blockInfo: Block | null;
  setBlockInfo: React.Dispatch<React.SetStateAction<any>>;
  setTreeViewLat: React.Dispatch<React.SetStateAction<number>>;
  setTreeViewLong: React.Dispatch<React.SetStateAction<number>>;
  setSectionGeojson: React.Dispatch<React.SetStateAction<any>>;
  setPolygonStats: React.Dispatch<React.SetStateAction<any>>;
  polygonStatDialog: boolean;
  setPolygonStatDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setAddToSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  isLoad: boolean;
  mapCenter: any;
  setMapCenter: React.Dispatch<React.SetStateAction<any>>;
  controlEnabled: boolean;
  initialLocation: Coordinate | null;
  treeData: any;
  emitterData: any;
  setControlEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setBlockData: React.Dispatch<React.SetStateAction<any>>;
  adminUser: boolean;
  treeViewLat: number;
  treeViewLong: number;
  sectionGeojson: any;
  sidebarWidth: number;
  setSidebarWidth: React.Dispatch<React.SetStateAction<number>>;
  scanInfo: ScanInfo | null;
  setScanInfo: React.Dispatch<React.SetStateAction<ScanInfo | null>>;
  fruitType: FruitType;
  qualifyingBlocks: string[] | null;
  setInitialLocation: React.Dispatch<React.SetStateAction<Coordinate | null>>;
  currentSeason: number;
  setCurrentSeason: React.Dispatch<React.SetStateAction<number>>;
  timelineData: any[];
  setTimelineData: React.Dispatch<React.SetStateAction<any[]>>;
  availableSeasons: string[];
  setAvailableSeasons: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Home: React.FC<HomeProps> = ({
  orchard,
  orchardCode,
  blockData,
  initialLoad,
  dbName,
  plotType,
  downloadFormat,
  setDownloadFormat,
  setPlotType,
  chartType,
  setChartType,
  mapInstance,
  scanLoading,
  setScanLoading,
  blockScanMap,
  entity,
  setProgress,
  progress,
  stats,
  setCurrentScanId,
  blockName,
  setBlockName,
  viewMode,
  setViewMode,
  currentScanId,
  addToSidebar,
  polygonStats,
  stageType,
  blockInfo,
  setBlockInfo,
  setTreeViewLat,
  setTreeViewLong,
  setSectionGeojson,
  setPolygonStats,
  polygonStatDialog,
  setPolygonStatDialog,
  setAddToSidebar,
  isLoad,
  mapCenter,
  setMapCenter,
  controlEnabled,
  initialLocation,
  treeData,
  emitterData,
  setControlEnabled,
  setBlockData,
  adminUser,
  treeViewLat,
  treeViewLong,
  sectionGeojson,
  sidebarWidth,
  setSidebarWidth,
  scanInfo,
  setScanInfo,
  fruitType,
  qualifyingBlocks,
  setInitialLocation,
  currentSeason,
  setCurrentSeason,
  timelineData,
  setTimelineData,
  availableSeasons,
  setAvailableSeasons
}) => {
    const { blockName: urlBlockName } = useParams<{ blockName: string }>();
    const navigate = useNavigate();


    const handlePressBack = () => {
      setPlotType('');
      setViewMode(ViewMode.Orchard);
      setCurrentScanId(null);
      setProgress(100);
      setTimelineData([]);
      setBlockInfo(null);
      setSectionGeojson(null);
      setScanInfo(null);
      if (orchard) {
        setAvailableSeasons(orchard.seasons);
      }
      setBlockName(null);
    }
    
    useEffect(() => {
      (async () => {
        if (urlBlockName && urlBlockName !== blockName) {
          setBlockName(urlBlockName);
          try {
            const blockInfo = await axios.get('/util/get_block_info', { params: { 'block_name': urlBlockName } });

            // If loading a block that does not have any current season data, default to most recent season
            if (!blockInfo.data.seasons.includes(currentSeason)) {
              setCurrentSeason(blockInfo.data.seasons[0]);
              setAvailableSeasons(blockInfo.data.seasons);
              await axios.post('/util/update_season', { season: blockInfo.data.seasons[0] });
          }
          } catch (error) {
            console.error('Error fetching block info:', error);
          }
        }
      })();
    }, [urlBlockName]);
    
    useEffect(() => {
      if (blockName) {
          navigate(`/block/${blockName}`);
      } else {
        navigate('/');
      }
    }, [blockName]);
    
        
  return blockData === null ? (
    <div className="h-screen flex flex-col justify-center items-center mx-auto mt-auto mb-auto">
      {dbName.current === 'NFM' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={NWF}></img>}
      {dbName.current === 'SAG' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={SAG}></img>}
      {dbName.current === 'ZRK' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={ZIR}></img>}
      {dbName.current === 'CHA' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={CHI}></img>}
      {dbName.current === 'SVO' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={SVO}></img>}
      {dbName.current === 'ABF' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={ALL}></img>}
      {dbName.current === 'STE' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={STE}></img>}
      <img className="max-w-[200px] max-h-[200px]" src={loading} alt="loading..." />
      <p>Loading... {initialLoad}%</p>
      <div className="progress-bar-container justify-center items-center w-full">
        <div className="mx-auto relative pt-1 max-w-[500px]">
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200">
            <div
              style={{ width: `${initialLoad}%` }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
            ></div>
          </div>
        </div>
      </div>
      <img className='absolute bottom-4 max-w-[200px] max-h-[200px]' src={MadeByOrchard}></img>
    </div>
  ) : (
<div className="grid" style={{ gridTemplateColumns: `${sidebarWidth}px 1fr` }}>
      <Toaster position='top-left' toastOptions={{
        success: { duration: 2000 },
        className: 'min-w-[23%]'
      }} />
      <Sidebar
        orchard={orchard}
        plotType={plotType}
        downloadFormat={downloadFormat}
        setDownloadFormat={setDownloadFormat}
        chartType={chartType}
        setChartType={setChartType}
        mapInstance={mapInstance}
        scanLoading={scanLoading}
        setScanLoading={setScanLoading}
        blockScanMap={blockScanMap}
        entity={entity}
        progress={progress}
        stats={stats}
        setCurrentScanId={setCurrentScanId}
        blockName={blockName}
        setBlockName={setBlockName}
        viewMode={viewMode}
        setViewMode={setViewMode}
        addToSidebar={addToSidebar}
        polygonStats={polygonStats}
        stageType={stageType}
        blockInfo={blockInfo}
        setTreeViewLat={setTreeViewLat}
        setTreeViewLong={setTreeViewLong}
        sidebarWidth={sidebarWidth}
        setSidebarWidth={setSidebarWidth}
        scanInfo={scanInfo}
        adminUser={adminUser}
        fruitType={fruitType}
        treeData={treeData}
        qualifyingBlocks={qualifyingBlocks}
        currentScanId={currentScanId}
        setInitialLocation={setInitialLocation}
        setScanInfo={setScanInfo}
        currentSeason={currentSeason}
        setCurrentSeason={setCurrentSeason}
        timelineData={timelineData}
        setTimelineData={setTimelineData}
        availableSeasons={availableSeasons}
        handlePressBack={handlePressBack}
      />
      <div style={{ visibility: (scanLoading || isLoad || progress !== 100) ? 'hidden' : 'visible'}}>
          <MapBox
            orchardCode={orchardCode}
            downloadFormat={downloadFormat}
            plotType={plotType}
            setPlotType={setPlotType}
            polygonStats={polygonStats}
            setPolygonStats={setPolygonStats}
            polygonStatDialog={polygonStatDialog}
            setPolygonStatDialog={setPolygonStatDialog}
            setAddToSidebar={setAddToSidebar}
            entity={entity}
            mapInstance={mapInstance}
            mapCenter={mapCenter}
            setMapCenter={setMapCenter}
            blockName={blockName}
            currentScanId={currentScanId}
            controlEnabled={controlEnabled}
            viewMode={viewMode}
            stats={stats}
            setViewMode={setViewMode}
            setBlockName={setBlockName}
            initialLocation={initialLocation}
            blockData={blockData}
            treeData={treeData}
            emitterData={emitterData}
            setControlEnabled={setControlEnabled}
            setBlockData={setBlockData}
            adminUser={adminUser}
            blockScanMap={blockScanMap}
            stageType={stageType}
            setTreeViewLat={setTreeViewLat}
            setTreeViewLong={setTreeViewLong}
            treeViewLat={treeViewLat}
            treeViewLong={treeViewLong}
            sectionGeojson={sectionGeojson}
            sidebarWidth={sidebarWidth}
            blockInfo={blockInfo}
            orchard={orchard}
            fruitType={fruitType}
            scanInfo={scanInfo}
          />
      </div>

      {(scanLoading || isLoad || progress !== 100) && (
        <div className="absolute inset-0 left-1/3 flex flex-col justify-center items-center">
          {dbName.current === 'SAG' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={SAG}></img>}
          {dbName.current === 'NWF' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={NWF}></img>}
          {dbName.current === 'ZIR' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={ZIR}></img>}
          {dbName.current === 'CHI' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={CHI}></img>}
          {dbName.current === 'SVO' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={SVO}></img>}
          {dbName.current === 'ALL' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={ALL}></img>}
          {dbName.current === 'STE' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={STE}></img>}
          <p className='font-lato text-gray-500'>Reloading map with new scan data...</p>
          <img className="max-w-[200px] max-h-[200px]" src={loading} alt="loading..." />
        </div>
      )}
    </div>
  );
      }