import { library } from '@fortawesome/fontawesome-svg-core';
import { faAppleWhole, faArrowDown, faArrowRight, faBorderAll, faCalendar, faCalendarDays, faCamera, faCaretLeft, faCaretRight, faChartArea, faChartSimple, faDownload, faEnvelope, faHardDrive, faHashtag, faHome, faLeaf, faLink, faLinkSlash, faLocationCrosshairs, faLocationDot, faMessage, faPaperPlane, faPrint, faQuestion, faRuler, faSeedling, faSpa, faStopwatch, faTag, faTowerCell, faTree, faVectorSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { BulkExport } from './BulkExport';
import { Block, BlockScanMap, Coordinate, EntityType, FruitType, Orchard, StageType, Statistics } from './common/types';
import { MapStateProvider } from './context/MapStateContext';
import firebase from './firebase';
import { Admin } from './routes/AdminMode';
import { Home } from './routes/Home';
import Login from './routes/Login';
import { Setup } from './routes/Setup';
import { Alert, Snackbar } from '@mui/material';


// is there a better way to library.add?
library.add(faSeedling, faStopwatch, faRuler, faCaretRight, faLocationDot, faCaretLeft, faMessage, faEnvelope, faPaperPlane, faXmark, faQuestion, faVectorSquare, faCamera, faLocationCrosshairs, faHardDrive, faTowerCell, faTree, faLink, faLinkSlash, faHome, faAppleWhole, faTag, faPrint, faDownload, faSpa, faChartArea, faLeaf, faHashtag, faCalendarDays, faBorderAll, faArrowRight, faArrowDown, faCalendar, faChartSimple);

const App = () => {
    /* eslint-disable */

    //TODO, apply where possible
    const [orchard, setOrchard] = useState<Orchard | null>(null);
    const [stats, setStats] = useState<Statistics | null>(null);
    const [sdmTableView, setSdmTableView] = useState<boolean>(false)
    const [truthfulScanName, setTruthfulScanName] = useState<string | null>(null)
    const [truthfulScanId, setTruthfulScanId] = useState<number | null>(null)
    const [user, setUser] = useState<firebase.User | null>(null);
    const [initLocation, setInitLocation] = useState<Coordinate>();
    const [orchardCode, setOrchardCode] = useState<string>('');
    const [sidebarViewMode, setSidebarViewMode] = useState<string>('Orchard');
    const [treeData, setTreeData] = useState(null);
    const [blockData, setBlockData] = useState(null);
    const [blockView, setBlockView] = useState<boolean>(false);
    const [blockName, setBlockName] = useState<string | null>(null);
    const [blockInfo, setBlockInfo] = useState<Block | null>(null);
    let time = new Date()
    const [timeFilter, setTimeFilter] = useState(() => {
        const storedTime = localStorage.getItem('timestamp');
        const defaultTime = time.toISOString();
        if (storedTime !== null) {
            const storedDateTime = new Date(storedTime);
            return storedDateTime.toISOString() !== '1970-01-01T00:00:00.000Z' ? storedDateTime.toISOString() : defaultTime;
        }
        return defaultTime;
    });
    const [authUser, setAuthUser] = useState<boolean>(false)
    const [adminUser, setAdminUser] = useState<boolean>(false)
    const [controlEnabled, setControlEnabled] = useState<boolean>(true);
    const [progress, setProgress] = useState(0);

    const [entity, setEntity] = useState<EntityType>(EntityType.Fruits);
    const [fruitType, setFruitType] = useState<FruitType>(FruitType.Apple);
    const [blockScanMap, setBlockScanMap] = useState<BlockScanMap>({});
    const [stageType, setStageType] = useState<StageType | null>(null);
    const [scanLoading, setScanLoading] = useState<boolean>(false)
    const [mapCenter, setMapCenter] = useState(null)
    const [fruitletDF, setFruitletDF] = useState(null)
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const mapInstance = useRef<mapboxgl.Map | null>(null);
    const [chartType, setChartType] = useState("row_sizes_")
    const [polygonStatDialog, setPolygonStatDialog] = useState<boolean>(false)
    const [addToSidebar, setAddToSidebar] = useState<boolean>(false)
    const [polygonStats, setPolygonStats] = useState<Statistics | null>(null);
    const [plotType, setPlotType] = useState<string | null>(null);
    const [downloadFormat, setDownloadFormat] = useState('share_geojson')
    const [selectedVarieties, setSelectedVarieties] = useState<any[]>([]);
    const [allowedVarieties, setAllowedVarieties] = useState<any[]>([]);
    const [enableRowFilter, setEnableRowFilter] = useState(false);
    const [initialLoad, setInitialLoad] = useState<number>(0);
    const [treeViewLong, setTreeViewLong] = useState<number>(0);
    const [treeViewLat, setTreeViewLat] = useState<number>(0);
    const [sectionGeojson, setSectionGeojson] = useState(null);
    const [homePageLoaded, setHomePageLoaded] = useState<boolean>(false);
    const [sidebarWidth, setSidebarWidth] = useState(window.innerWidth * .33);
    const [scanInfo, setScanInfo] = useState(null);

    const [qualifyingBlocks, setQualifyingBlocks] = useState<string[] | null>(null);


    const dbName = useRef('')
    let didInit = false;

    const [error, setError] = useState<string | null>(null);

    const fetchQualifyingBlocks = async () => {
        try {
            const response = await axios.get('/util/get_qualifying_blocks');
            setQualifyingBlocks(response.data);
        } catch (error) {
            console.error('Error fetching qualifying blocks:', error);
        }
    };
    

    useEffect(() => {
        if (!didInit) {
            // Only run this once
            didInit = true;
            firebase.auth().onAuthStateChanged(async (firebase_user) => {
                try {
                    if (authUser !== true) {
                        setUser(firebase_user);
                        const authenticateUser = await axios.post('/auth_user', {
                            username: firebase_user,
                            timestamp: timeFilter,
                            loggedIn: authUser,
                        });
                        if (authenticateUser.data.error) {
                            throw new Error('Authentication failed');
                        }
                        dbName.current = authenticateUser.data.db;
                        setAuthUser(true);
                        setAdminUser(authenticateUser.data.is_admin ? true : false);
                    }
                    setInitialLoad(20);

                    const [
                        coordsResponse,
                        orchDataResponse,
                        blockPointsResponse,
                        blocksToScansResponse,
                    ] = await Promise.all([
                        axios.get('/util/get_init_location').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 20);
                            return response;
                        }),
                        axios.get('/util/get_orchard').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 20);
                            return response;
                        }),
                        axios.get('/geojson/points_block').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 20);
                            return response;
                        }),
                        axios.get('/util/get_block_scan_map').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 10);
                            return response;
                        }),
                    ]);

                    const coords = coordsResponse.data;
                    setInitLocation(coords);
                    setTreeViewLat(coords.lat);
                    setTreeViewLong(coords.long);

                    const orchData = orchDataResponse.data;
                    setOrchard({
                        name: orchData.orchard_name,
                        address: orchData.address,
                        code: orchData.orchard_id,
                        initialLocation: {
                            lat: orchData.location.lat,
                            long: orchData.location.long,
                        },
                        total_acres: orchData.total_acres,
                        section_code_prefix: orchData.section_code_prefix,
                    });
                    setOrchardCode(orchData.orchard_id);

                    setBlockData(blockPointsResponse.data);
                    setBlockScanMap(blocksToScansResponse.data);

                    setInitialLoad(100);
                    setProgress(100);
                    setHomePageLoaded(true);
                } catch (error) {
                    console.error('Error:', error);
                    const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
                    setError(errorMessage);
                    setProgress(0);
                    await axios.post('/user/send_error', { message: `${errorMessage}` });
                }
            });
        }
    }, []);

    useEffect(() => {
        if (homePageLoaded) {
            fetchQualifyingBlocks();
        }
    }, [homePageLoaded]);

    useEffect(() => {
        (async () => {
            setIsLoad(true)
            setEnableRowFilter(false)
            setAllowedVarieties([])
            if (blockName && homePageLoaded) {
                setBlockView(true)
                setSidebarViewMode('Block')
                
                setTruthfulScanName(null);
                setTruthfulScanId(null);
                setAddToSidebar(false)
                const truthfulRes = await axios.get('/util/block_truthful_scans', { params: { 'orchard_code': orchardCode, 'block': blockName } });
                setTruthfulScanName(truthfulRes.data.raw_scan_name);
                setTruthfulScanId(truthfulRes.data.scan_id);

                const blockInfo = await axios.get('/util/get_block_info', { params: { 'block_name': blockName } });
                setBlockInfo(blockInfo.data);
                setTreeViewLat(blockInfo.data.location.lat)
                setTreeViewLong(blockInfo.data.location.long)

                const varietyinfo = await axios.get('/util/variety_list', { params: { 'block_id': blockName } })
                setSelectedVarieties(varietyinfo.data)
            }
                setIsLoad(false)

        })();
    }, [blockName, homePageLoaded]);

    useEffect(() => {
        (async () => {
            if (truthfulScanName) {
                setProgress(0);
                setScanLoading(true);
                try {
                    const apiCalls = [
                        // TODO: Rename scan_id param to scan_name
                        axios.get('/geojson/points', { params: { 'orchard_code': orchardCode, 'scan_id': truthfulScanName } }),
                        axios.get('/util/get_scan_info', { params: { 'scan_id': truthfulScanName } }),
                        axios.post('/util/get_stats', { orchard_code: orchardCode, scan_name: truthfulScanName })
                    ];
                    const [
                        treedata,
                        scanInfo,
                        stats,
                    ] = await Promise.all(apiCalls);

                    console.log("Stats: ", stats)
                    console.log("Scan Info: ", scanInfo)
                    setEntity(scanInfo.data.entity_type);
                    setFruitType(scanInfo.data.fruit_type);
                    setScanInfo(scanInfo.data);
                    setStageType(scanInfo.data.stage_type);
                    setTreeData(treedata.data);
                    
                    if (scanInfo.data.entity_type === EntityType.Fruits) {
                        if (scanInfo.data.stage_type === StageType.Fruit) {
                            setPlotType('uniform')
                        }
                        else if (scanInfo.data.stage_type === StageType.EarlyFruitSet) {
                            setPlotType('size')
                        }
                        else {
                            setPlotType('uniform')
                        }
                    } else {
                        setPlotType('tree_diam')
                    }
                    setStats(stats.data)
                    // Get calibration sections
                    const response = await axios.get('/geojson/section_geojson', {
                        params: {
                            'orchard_code': orchardCode,
                            'scan_id': truthfulScanName
                        }
                    });
                    console.log("Section Geojson", response.data)
                    setSectionGeojson(response.data);
                    setScanLoading(false)
                    setProgress(100)
                } catch (error) {
                    console.error('Error:', error);
                    const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
                    setError(errorMessage);
                    setProgress(0);
                    await axios.post('/user/send_error', { message: `${errorMessage}` });
                }
            }
    
        })();

    }, [truthfulScanName]);
    
    if (user) {
        return (
            <Router>
                <MapStateProvider>
                    <Routes>
                        <Route path="/setup" element={<Setup />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route
                            path="/bulk"
                            element={<BulkExport truthfulScanName={truthfulScanName} />}
                        />
                        <Route
                            path="/block/:blockName?"
                            element={
                                <Home
                                    orchard={orchard}
                                    orchardCode={orchardCode}
                                    fruitletDF={fruitletDF}
                                    blockData={blockData}
                                    initialLoad={initialLoad}
                                    dbName={dbName}
                                    plotType={plotType}
                                    selectedVarieties={selectedVarieties}
                                    downloadFormat={downloadFormat}
                                    setDownloadFormat={setDownloadFormat}
                                    setPlotType={setPlotType}
                                    chartType={chartType}
                                    setChartType={setChartType}
                                    mapInstance={mapInstance}
                                    scanLoading={scanLoading}
                                    setScanLoading={setScanLoading}
                                    blockScanMap={blockScanMap}
                                    entity={entity}
                                    setProgress={setProgress}
                                    progress={progress}
                                    stats={stats}
                                    setTruthfulScanName={setTruthfulScanName}
                                    setTruthfulScanId={setTruthfulScanId}
                                    blockView={blockView}
                                    blockName={blockName}
                                    setBlockName={setBlockName}
                                    sidebarViewMode={sidebarViewMode}
                                    setSidebarViewMode={setSidebarViewMode}
                                    setBlockView={setBlockView}
                                    truthfulScanName={truthfulScanName}
                                    truthfulScanId={truthfulScanId}
                                    setSdmTableView={setSdmTableView}
                                    sdmTableView={sdmTableView}
                                    addToSidebar={addToSidebar}
                                    polygonStats={polygonStats}
                                    enableRowFilter={enableRowFilter}
                                    stageType={stageType}
                                    blockInfo={blockInfo}
                                    setBlockInfo={setBlockInfo}
                                    setTreeViewLat={setTreeViewLat}
                                    setTreeViewLong={setTreeViewLong}
                                    setSectionGeojson={setSectionGeojson}
                                    allowedVarieties={allowedVarieties}
                                    setPolygonStats={setPolygonStats}
                                    polygonStatDialog={polygonStatDialog}
                                    setPolygonStatDialog={setPolygonStatDialog}
                                    setAddToSidebar={setAddToSidebar}
                                    isLoad={isLoad}
                                    mapCenter={mapCenter}
                                    setMapCenter={setMapCenter}
                                    controlEnabled={controlEnabled}
                                    initialLocation={initLocation}
                                    treeData={treeData}
                                    setControlEnabled={setControlEnabled}
                                    setBlockData={setBlockData}
                                    adminUser={adminUser}
                                    treeViewLat={treeViewLat}
                                    treeViewLong={treeViewLong}
                                    sectionGeojson={sectionGeojson}
                                    sidebarWidth={sidebarWidth}
                                    setSidebarWidth={setSidebarWidth}
                                    scanInfo={scanInfo}
                                    fruitType={fruitType}
                                    qualifyingBlocks={qualifyingBlocks}
                                />
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <Home
                                    orchard={orchard}
                                    orchardCode={orchardCode}
                                    fruitletDF={fruitletDF}
                                    blockData={blockData}
                                    initialLoad={initialLoad}
                                    dbName={dbName}
                                    plotType={plotType}
                                    selectedVarieties={selectedVarieties}
                                    downloadFormat={downloadFormat}
                                    setDownloadFormat={setDownloadFormat}
                                    setPlotType={setPlotType}
                                    chartType={chartType}
                                    setChartType={setChartType}
                                    mapInstance={mapInstance}
                                    scanLoading={scanLoading}
                                    setScanLoading={setScanLoading}
                                    blockScanMap={blockScanMap}
                                    entity={entity}
                                    setProgress={setProgress}
                                    progress={progress}
                                    stats={stats}
                                    setTruthfulScanName={setTruthfulScanName}
                                    setTruthfulScanId={setTruthfulScanId}
                                    blockView={blockView}
                                    blockName={blockName}
                                    setBlockName={setBlockName}
                                    sidebarViewMode={sidebarViewMode}
                                    setSidebarViewMode={setSidebarViewMode}
                                    setBlockView={setBlockView}
                                    truthfulScanName={truthfulScanName}
                                    truthfulScanId={truthfulScanId}
                                    setSdmTableView={setSdmTableView}
                                    sdmTableView={sdmTableView}
                                    addToSidebar={addToSidebar}
                                    polygonStats={polygonStats}
                                    enableRowFilter={enableRowFilter}
                                    stageType={stageType}
                                    blockInfo={blockInfo}
                                    setBlockInfo={setBlockInfo}
                                    setTreeViewLat={setTreeViewLat}
                                    setTreeViewLong={setTreeViewLong}
                                    setSectionGeojson={setSectionGeojson}
                                    allowedVarieties={allowedVarieties}
                                    setPolygonStats={setPolygonStats}
                                    polygonStatDialog={polygonStatDialog}
                                    setPolygonStatDialog={setPolygonStatDialog}
                                    setAddToSidebar={setAddToSidebar}
                                    isLoad={isLoad}
                                    mapCenter={mapCenter}
                                    setMapCenter={setMapCenter}
                                    controlEnabled={controlEnabled}
                                    initialLocation={initLocation}
                                    treeData={treeData}
                                    setControlEnabled={setControlEnabled}
                                    setBlockData={setBlockData}
                                    adminUser={adminUser}
                                    treeViewLat={treeViewLat}
                                    treeViewLong={treeViewLong}
                                    sectionGeojson={sectionGeojson}
                                    sidebarWidth={sidebarWidth}
                                    setSidebarWidth={setSidebarWidth}
                                    scanInfo={scanInfo}
                                    fruitType={fruitType}
                                    qualifyingBlocks={qualifyingBlocks}
                                />
                            }
                        />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </MapStateProvider>
                <Snackbar 
                    open={!!error} 
                    autoHideDuration={8000} 
                    onClose={() => setError(null)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert 
                        onClose={() => setError(null)} 
                        severity="error" 
                        variant="filled"
                        className="w-full"
                    >
                        {error}
                    </Alert>
                </Snackbar>
            </Router>
        );
    } else {
        return (
            <div className="bg-orchardGreen font-lato grid h-screen flex flex-col place-items-center">
                <Login />
            </div>
        );
    }
}

export default App;