export const TreeSearchIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 640 512"
    className="w-5 h-5"
    fill="currentColor"
  >
    <path d="M0 288c0-39.7 24.1-73.8 58.5-88.4C51.8 187.9 48 174.4 48 160c0-44.2 35.8-80 80-80c11.5 0 22.5 2.4 32.4 6.8C165 38.1 206.1 0 256 0s91 38.1 95.6 86.8c9.9-4.4 20.9-6.8 32.4-6.8c44.2 0 80 35.8 80 80c0 .3 0 .5 0 .8c-5.3-.5-10.6-.8-16-.8c-88.4 0-160 71.6-160 160c0 22.8 4.7 44.4 13.3 64L288 384l0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-96L96 384c-53 0-96-43-96-96zm320 32c0-70.7 57.3-128 128-128s128 57.3 128 128c0 26.7-8.2 51.4-22.1 71.9L633 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L520 425.8l-.1 .1C499.4 439.8 474.7 448 448 448c-70.7 0-128-57.3-128-128zm48 0c0 28.6 15.2 55 40 69.3s55.2 14.3 80 0s40-40.7 40-69.3s-15.2-55-40-69.3s-55.2-14.3-80 0s-40 40.7-40 69.3z"/>
  </svg>
);

export const GroundTruthEditIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 640 512" 
    className="w-5 h-5"
    fill="currentColor"
  >
    <path d="M0 256C0 164.5 48.8 80 128 34.3s176.8-45.7 256 0c73.5 42.5 120.8 118.3 127.3 202.1l-49.4 49.4c1.4-9.8 2.1-19.8 2.1-29.8c0-74.3-39.6-143-104-180.1s-143.6-37.2-208 0S48 181.7 48 256s39.6 143 104 180.1c54.6 31.5 120 36.3 177.9 14.3l-8.5 33.8c-1.6 6.5-1.8 13.1-.8 19.5c-64.3 16.8-133.6 8.1-192.6-26C48.8 432 0 347.5 0 256zM353.5 492.1l15-60.1c1.4-5.7 4.3-10.8 8.4-14.9L506.2 287.9l-.1 0 71 71L447.9 488.1c-4.1 4.1-9.3 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2zM528.8 265.3l29.4-29.4c15.7-15.6 41-15.6 56.6 0l14.3 14.3c15.6 15.7 15.6 41 0 56.6l-29.4 29.4-70.9-70.9z"/>
  </svg>
);