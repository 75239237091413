import { Button, Dialog, DialogContent, Typography,  } from '@mui/material';
import React, { useState } from 'react';
import pulsing from '../assets/pulsing.gif';
import { FeedbackForm } from '../sidebar/components/FeedbackForm';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { ScanInfo } from '../common/types';

interface HelpControlProps {
    scanInfo: ScanInfo | null;
}

export const HelpControl: React.FC<HelpControlProps> = ({ scanInfo }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <>
            <Button
                variant="contained"
                className="!min-w-0 !p-1 !bg-white !text-black hover:!bg-gray-100 !shadow-md"
                onClick={() => setIsDialogOpen(true)}
            >
                <QuestionMarkIcon/>
            </Button>

            <Dialog 
                open={isDialogOpen} 
                onClose={() => setIsDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent className="flex flex-col items-center">
                    <Typography variant="h5" className="font-bold mb-4">
                        Need help?
                    </Typography>
                    
                    <Typography variant="body2" className="text-gray-700 mb-4">
                        Charlie from Orchard Robotics is available now to call or email, or leave feedback in the form below:
                    </Typography>

                    <div className="flex items-center mb-2">
                        <img src={pulsing} alt="green-dot" className="h-8 w-8 mr-2 animate-pulse" />
                        <Typography variant="body1">
                            (315) 359-7337
                        </Typography>
                    </div>

                    <div className="flex items-center mb-4">
                        <img src={pulsing} alt="green-dot" className="h-8 w-8 mr-2 animate-pulse" />
                        <a 
                            href="mailto:charlie@orchard-robotics.com"
                            className="text-blue-500 hover:text-blue-700 underline"
                        >
                            charlie@orchard-robotics.com
                        </a>
                    </div>

                    <hr className="w-full my-4" />
                    
                    <FeedbackForm rawScanName={scanInfo?.raw_scan_name} />
                </DialogContent>
            </Dialog>
        </>
    );
};