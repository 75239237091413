import React, { useState, useEffect } from "react";
import { Select, MenuItem, Checkbox, FormControl, InputLabel, List, ListItem, ListItemIcon, ListItemText, Grid, TextField } from "@mui/material";
import { DateTime } from "luxon";
import axios from 'axios';
import logo from './assets/logo.svg';
import { useTable } from 'react-table';
import { Button } from "@mui/material";
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { HelpControl } from './map/HelpControl';
import loading from './assets/loading.gif';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const Branding = () => {
    const handleBack = (e) => {
        e.preventDefault();
        window.location.pathname = '/'
    };
    return (
        <div id='branding' className='flex flex-row justify-between items-center p-1 bg-orchardGreen font-lato text-white'>
            <div className='flex flex-row items-center'>
                <img className='m-1' src={logo} width={90} alt='Orchard Robotics Logo' />
                <div>
                    <h1 className='font-bold text-3xl mt-3'>FruitScope Cloud Collective Data View</h1>
                    <h2 className='text-sm text-left'> by Orchard Robotics</h2>
                </div>
            </div>
            <button
                className="drop-shadow-md mr-4 relative bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-1 px-5 rounded-xl my-4"
                onClick={handleBack}
            >
                Back to FruitScope Cloud
            </button>
        </div>
    );
};


export const FruitletExport = ({ scanInfo, data }) => {
    const [copyPressed, setCopyPressed] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function convertToCSV(objArray) {
        let result = '';
        //for paul, CSV, else TSV
        if (scanInfo.raw_scan_name.includes('CHI')) {
            // Append the column headers
            result += "Block Name,Scan ID,Fruitlet ID,Tree ID,Diameter Width (mm),Fruitlet Length (mm),Volume (mm^3)\n";

            // Append each row
            formattedData.forEach((row) => {
                result += `${row.block_id},${row.scan_id},${row.bud_id},${row.tree_id},${row.minor_ax},${row.major_ax},${row.volume_mm}\n`;
            });

        }
        else {
            // Append the column headers
            result += "Block Name\tScan ID\tFruitlet ID\tTree ID\tDiameter Width (mm)\tFruitlet Length (mm)\tVolume (mm^3)\n";

            // Append each row
            formattedData.forEach((row) => {
                result += `${row.block_id}\t${row.scan_id}\t${row.bud_id}\t${row.tree_id}\t${row.minor_ax}\t${row.major_ax}\t${row.volume_mm}\n`;
            });
        }
        return result;
    }

    // Format data
    const formattedData = data.map(row => ({
        ...row,
        minor_ax: parseFloat(row.minor_ax).toFixed(4),
        major_ax: parseFloat(row.major_ax).toFixed(4),
        volume_mm: parseFloat(row.volume_mm).toFixed(4),
    }));
    // console.log("data in fruitlet export", formattedData)

    const columns = React.useMemo(
        () => [
            {
                Header: 'Block Name', // Add new column
                accessor: 'block_id', // new accessor
            },
            {
                Header: 'Scan Name', // Add new column
                accessor: 'scan_id', // new accessor
            },
            {
                Header: 'Fruitlet ID',
                accessor: 'bud_id',
            },
            {
                Header: 'Tree ID',
                accessor: 'tree_id',
            },
            {
                Header: 'Diameter Width (mm)',
                accessor: 'minor_ax',
            },
            {
                Header: 'Fruitlet Length (mm)',
                accessor: 'major_ax',
            },
            {
                Header: 'Volume (mm\u00B3)',
                accessor: 'volume_mm',
            },
        ],
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: formattedData });

    return (
        <div className='font-lato overflow-hidden relative w-[60vw] max-h-[100vh]' >
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogContent>
                    <div className="flex items-center">
                        <h1 className="text-3xl font-bold mb-2 mr-4 ">Fruitlet Size Distribution Model</h1>
                        <span className="inline-block bg-rose-500 text-white rounded-full px-6 py-1 text-sm font-bold mb-2">BETA</span>
                    </div>
                    <hr className='border-gray-300 my-2' />
                    <p className="my-1 text-md">
                        1.  Credit for the Fruitlet Size Distribution (FSD) Model goes to Laura Hillmann and {' '}
                        <a href="https://www.canr.msu.edu/people/dr_todd_einhorn" target="_blank" rel="noopener noreferrer" className="text-red-500 underline">
                            Todd Einhorn
                        </a>
                        {' '} at MSU for creating this model and easy-to-use Excel implementation.
                    </p>
                    <p className="my-1 text-md">
                        2. Instructions to use the FSD Model can be found {' '}
                        <a href="https://pacman.extension.org/2023/03/06/the-fruitlet-size-distribution-fsd-model-a-how-to-guide/" target="_blank" rel="noopener noreferrer" className="text-red-500 underline">
                            here
                        </a>
                        , {' '} on the PACMAN Extension website.
                    </p>
                    <p className="my-1 text-md">
                        3. You can download the {' '}
                        <a href="https://www.dropbox.com/scl/fi/x61v11umcczszo8rl68f4/FS-Predict.xlsm?dl=0&rlkey=nrctykltjyon8m79uvvvh69t9" target="_blank" rel="noopener noreferrer" className="text-red-500 underline">
                            Fruitlet Size Distribution Model Excel
                        </a>
                        {' '} file here.
                    </p>
                    <p className="my-1 text-md">4. Below are fruitlet diameter width values, along with fruitlet volume values, that can be copied directly into the model, using the <i>Copy Data</i> button.</p>
                    <p className="my-1 text-md ">5. If you need any support or have any questions, feel free to contact us below at the <i>Need Help?</i> button in the bottom right. </p>
                </DialogContent>
            </Dialog>
            <p className="my-1 text-red-500 text-md"> Please read the Fruitlet Size Distribution (FSD) Model instructions on how to use this data by clicking the button below.</p>
            {/* <p className="my-1 text-md ">5. If you need any support or have any questions, feel free to contact us below at the <i>Need Help?</i> button in the bottom right. </p> */}
            <button className='btn-primary my-1'
                onClick={() => {
                    setCopyPressed(true)
                    if (navigator.clipboard) {
                        navigator.clipboard.writeText(convertToCSV(data))
                    } else {
                        // Fallback for older browsers
                        var textarea = document.createElement("textarea");
                        textarea.textContent = convertToCSV(data);
                        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                        document.body.appendChild(textarea);
                        textarea.select();
                        try {
                            document.execCommand("copy");  // Security exception may be thrown by some browsers.
                        } catch (ex) {
                            console.warn("Copy to clipboard failed.", ex);
                        } finally {
                            document.body.removeChild(textarea);
                        }
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            setCopyPressed(false)
                            setOpenSnackbar(false);
                        }, 2000);
                    }
                }}>
                {!copyPressed ? <p> Copy Data</p> : <p> Data Copied! </p>}
            </button>
            <button className="drop-shadow-md relative bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-1 px-5 rounded-xl mb-1 mt-2 ml-2" onClick={handleClickOpen}>
                Read Fruitlet FSD Instructions
            </button>
            <div className='mt-4 overflow-y-auto min-h-[5vh] max-h-[40vh] max-w-[63vw] min-w-full scrollbar-thin scrollbar-visible scrollbar-thumb-gray-400 scrollbar-track-gray-200"'>
                <table {...getTableProps()} className='min-w-full divide-y divide-gray-200'>
                    <thead>
                        {headerGroups.map((headerGroup, groupIndex) => (
                            <tr key={groupIndex} {...headerGroup.getHeaderGroupProps()} className='divide-x divide-gray-200'>
                                {headerGroup.headers.map((column, columnIndex) => (
                                    <th key={columnIndex} {...column.getHeaderProps()} className='px-4 py-3 text-sm bg-slate-200 font-bold tracking-wider text-left text-gray-700 uppercase'>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className='min-w-full bg-white divide-y divide-gray-200'>
                        {rows.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                                <tr key={rowIndex} {...row.getRowProps()} className='min-w-full hover:bg-emerald-50 divide-x divide-gray-200'>
                                    {row.cells.map((cell, cellIndex) => (
                                        <td key={cellIndex} {...cell.getCellProps()} className='min-w-full px-4 py-2 text-sm text-gray-500'>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <HelpControl />

            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    Data copied!
                </Alert>
            </Snackbar>
        </div>
    );
}

export const CombinedDataFrame = ({ data, entityType }) => {

    const columns = React.useMemo(
        () => [
            {
                Header: 'Scan Number',
                accessor: 'Scan Number',
            },
            {
                Header: 'Block Name',
                accessor: 'Block Name',
            },
            {
                Header: 'Tree ID',
                accessor: 'Tree ID',
            },
            {
                Header: 'Row',
                accessor: 'Row',
            },
            {
                Header: 'Directional ID',
                accessor: 'Directional ID',
            },
            {
                Header: 'Opposite Directional ID',
                accessor: 'Opposite Directional ID',
            },
            {
                Header: 'Lat',
                accessor: 'Lat',
            },
            {
                Header: 'Lon',
                accessor: 'Lon',
            },
            {
                Header: 'Trunk Area',
                accessor: 'Trunk Area',
            },
            {
                Header: `Number of entities (Calibrated)`,
                accessor: `Number of entities(Calibrated)`,
            },
            {
                Header: `Number of entities (Detected)`,
                accessor: `Number of entities (Detected)`,
            },
            {
                Header: 'Tree-specific Target Buds',
                accessor: 'Tree-specific Target Buds',
            },
            {
                Header: 'Tree-specific Target Fruiting Buds',
                accessor: 'Tree-specific Target Fruiting Buds',
            },
            {
                Header: 'Tree-specific Target Blossoms',
                accessor: 'Tree-specific Target Blossoms',
            },
            {
                Header: 'Tree-specific Target Fruits',
                accessor: 'Tree-specific Target Fruits',
            },
            {
                Header: 'Uniform Target',
                accessor: 'Uniform Target',
            },
            {
                Header: 'Difference from Tree-specific Target (Calibrated)',
                accessor: 'Difference from Tree-specific Target (Calibrated)',
            },
            {
                Header: 'Difference from Tree-specific Target (Detected)',
                accessor: 'Difference from Tree-specific Target (Detected)',
            },
            {
                Header: 'Difference from Uniform Target (Calibrated)',
                accessor: 'Difference from Uniform Target (Calibrated)',
            },
            {
                Header: 'Difference from Uniform Target (Detected)',
                accessor: 'Difference from Uniform Target (Detected)',
            },
            {
                Header: `Calibrated entities to Remove by Tree Target`,
                accessor: `Calibrated entities to Remove by Tree Target`,
            },
            {
                Header: `Detected entities to Remove by Tree Target`,
                accessor: `Detected entities to Remove by Tree Target`,
            },
            {
                Header: `Calibrated entities to Remove by Uniform`,
                accessor: `Calibrated entities to Remove by Uniform`,
            },
            {
                Header: `Detected entities to Remove by Uniform`,
                accessor: `Detected entities to Remove by Uniform`,
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: data });

    return (
        <div className='font-lato overflow-hidden relative w-[60vw] max-h-[100vh]' >
            <div className='mt-4 overflow-y-auto min-h-[30vh] max-h-[50vh] max-w-[63vw] min-w-full scrollbar-thin scrollbar-visible scrollbar-thumb-gray-400 scrollbar-track-gray-200"'>
                <table {...getTableProps()} className='min-w-full divide-y divide-gray-200'>
                    <thead>
                        {headerGroups.map((headerGroup, groupIndex) => (
                            <tr key={groupIndex} {...headerGroup.getHeaderGroupProps()} className='divide-x divide-gray-200'>
                                {headerGroup.headers.map((column, columnIndex) => (
                                    <th key={columnIndex} {...column.getHeaderProps()} className='px-4 py-3 text-sm bg-slate-200 font-bold tracking-wider text-left text-gray-700 uppercase'>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className='min-w-full bg-white divide-y divide-gray-200'>
                        {rows.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                                <tr key={rowIndex} {...row.getRowProps()} className='min-w-full hover:bg-emerald-50 divide-x divide-gray-200'>
                                    {row.cells.map((cell, cellIndex) => (
                                        <td key={cellIndex} {...cell.getCellProps()} className='min-w-full px-4 py-2 text-sm text-gray-500'>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};


export const BulkExport = ({scanInfo}) => {
    const [scans, setScans] = useState([]);
    const [filteredScans, setFilteredScans] = useState([]);
    const [selectedBlock, setSelectedBlock] = useState([]);
    const [selectedEntityType, setSelectedEntityType] = useState([]);
    const [selectedVarietyType, setSelectedVarietyType] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [varieties, setVarieties] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [combinedData, setCombinedData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dialog1Open, setDialog1Open] = React.useState(false);
    const [dialog2Open, setDialog2Open] = React.useState(false);
    const [dialog3Open, setDialog3Open] = React.useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [combinedFruitletDF, setCombinedFruitletDF] = useState(null)
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [scansLoading, setScansLoading] = useState(false)
    const [graphLoading, setGraphLoading] = useState(false)

    const handleEntityTypeChange = (event) => {
        const selectedValues = event.target.value;
        if (selectedValues.includes("early fruitlets") && selectedValues.length > 1) {
            setDialog2Open(true);
            setSelectedEntityType([]);  // deselect all entity types
        }
        else if (selectedValues.includes("trees") && selectedValues.length > 1) {
            setDialog3Open(true);
            setSelectedEntityType([]);  // deselect all entity types
        } else {
            setSelectedEntityType(selectedValues);
        }
    }


    const handleDialogClose = () => {
        setDialog2Open(false);
        setDialog3Open(false);
    }
    const handleCloseDialog = () => {
        setDialog1Open(false);
        setDialogMessage("");  // reset dialog message
    }

    const handleToggle = (id) => {
        setCheckedItems(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };

    const renderDate = (scanName) => {
        let d = new Date(scanName.substring(scanName.lastIndexOf('_') + 1));
        if (isNaN(d.getTime())) {
            scanName = scanName.substring(0, scanName.lastIndexOf('_'));
            d = new Date(scanName.substring(scanName.lastIndexOf('_') + 1)); // try again with 2nd-to-last

        }
        return d.toLocaleString([], {
            month: '2-digit',
            day: '2-digit'
        });
    };

    const sendScansToServer = async () => {
        // get the selected scan IDs from the checkedItems object
        const selectedScans = scans.filter(scan => checkedItems[scan.scan_id]).map(scan => scan.scan_id);
        console.log("selected scans", selectedScans)
        const dataToSend = {
            uniform: 150,  // update these variables to their actual values
            block_id: 'N/A', // update these variables to their actual values
            scans: selectedScans
        };

        try {
            const response = await axios.post('/export/bulk_exports', dataToSend);
            console.log(response.data);
            setCombinedData(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const downloadExcel = async () => {
        setIsLoading(true)
        const selectedScans = scans.filter(scan => checkedItems[scan.scan_id]).map(scan => scan.scan_id);
        if (selectedEntityType.includes("early fruitlets")) {
            const response = await axios.post('/export/bulk_fruitlet_df', { scans: selectedScans });
            setCombinedFruitletDF(response.data)

        }
        else {
            const dataToSend = {
                uniform: 150,  // update these variables to their actual values
                block_id: 'N/A', // update these variables to their actual values
                scans: selectedScans
            };
            const response = await axios.post('/export/bulk_exports', dataToSend, {
                responseType: 'blob', // Important
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = `combined_export.csv`;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
        setIsLoading(false)
    }

    const downloadIndividualReport = async () => {
        setIsLoading(true)
        try {
            const selectedScans = scans.filter(scan => checkedItems[scan.scan_id]).map(scan => scan.scan_id);
            const dataToSend = {
                scans: selectedScans
            };
            const response = await axios.post('/export/individual_report', dataToSend, {
                responseType: 'blob', // Important
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = `${selectedScans.join('_')}ScanReport.pdf`;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
        catch (error) {
            console.log("request failed", error)
        }
        setIsLoading(false)
    }

    const downloadAggregateReport = async () => {
        setIsLoading(true)
        try {
            const selectedScans = scans.filter(scan => checkedItems[scan.scan_id]).map(scan => scan.scan_id);
            const dataToSend = {
                scans: selectedScans
            };
            const response = await axios.post('/export/aggregate_report', dataToSend, {
                responseType: 'blob', // Important
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = `${selectedScans.join('_')}AggregateScanReport.pdf`;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
        catch (error) {
            setIsLoading(false)
            console.log("request failed", error)
        }
        setIsLoading(false)
    }

    const downloadGraphReport = async () => {
        setGraphLoading(true)
        const selectedScans = scans.filter(scan => checkedItems[scan.scan_id]).map(scan => scan.scan_id);
        if (selectedEntityType.includes("early fruitlets") || selectedEntityType.includes("fruitlets") || selectedEntityType.includes("fruits")) {
            const dataToSend = {
                scans: selectedScans
            };
            const response = await axios.post('/export/bulk_exports', dataToSend, {
                responseType: 'blob', // Important
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = `${selectedScans.join('_')}_graphs.pdf`;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
        else {
            const dataToSend = {
                uniform: 150,  // update these variables to their actual values
                block_id: 'N/A', // update these variables to their actual values
                scans: selectedScans
            };
            const response = await axios.post('/export/bulk_exports', dataToSend, {
                responseType: 'blob', // Important
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = `combined_export.csv`;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
        setGraphLoading(false)
    }

    const fetchBlocks = async () => {
        try {
            const response = await axios.get('/util/block_list');
            // replace '/path_to_your_endpoint' with your actual endpoint
            if (response.status === 200) {
                setBlocks(response.data);
                // assuming response.data is an array of block objects
            } else {
                console.log('Error while fetching blocks:', response.status);
            }
        } catch (error) {
            console.log('Error while fetching blocks:', error);
        }
    }

    const fetchVarieties = async () => {
        try {
            const response = await axios.get('/util/bulk_variety_list');
            // replace '/path_to_your_endpoint' with your actual endpoint
            if (response.status === 200) {
                setVarieties(response.data);
                // assuming response.data is an array of block objects
            } else {
                console.log('Error while fetching varieties:', response.status);
            }
        } catch (error) {
            console.log('Error while fetching varieties:', error);
        }
    }

    const handleFetchScans = async () => {
        if (selectedEntityType.length === 0) {
            setDialog1Open(true);
            setDialogMessage("You must select Stage Type filters before you can proceed. Other filters like Block and Variety type are optional.");
        } else {
            try {
                setScans([])
                setScansLoading(true)
                const response = await axios.post('/util/scan_list', {
                    block: selectedBlock,
                    entity_type: selectedEntityType,
                    variety_type: selectedVarietyType,
                    start_date: startDate,
                    end_date: endDate
                });
                if (response.data.error) {
                    console.error(response.data.error);
                } else {
                    setScans(response.data); // assuming you have a `scans` state variable
                    console.log("scan list", response.data)
                    setScansLoading(false)
                }
            } catch (error) {
                console.error(error);
                setScansLoading(false)
            }
            setScansLoading(false)
        }
    };

    // On mount, fetch the scan data from your API
    useEffect(() => {
        fetchBlocks();
        fetchVarieties();
        // fetchScans();
    }, []);

    useEffect(() => {
        let result = [...scans];
        if (selectedBlock) {
            result = result.filter(scan => scan.block === selectedBlock);
        }
        if (selectedEntityType) {
            result = result.filter(scan => scan.entity_type === selectedEntityType);
        }
        if (selectedVarietyType) {
            result = result.filter(scan => scan.variety_type === selectedVarietyType);
        }
        if (startDate && endDate) {
            const start = DateTime.fromJSDate(startDate);
            const end = DateTime.fromJSDate(endDate);
            result = result.filter(scan => {
                const scanTime = DateTime.fromISO(scan.time);
                return scanTime >= start && scanTime <= end;
            })
        }
        setFilteredScans(result);
    }, [selectedBlock, selectedEntityType, selectedVarietyType, startDate, endDate, scans]);

    const scansByBlock = scans.reduce((acc, scan) => {
        if (!acc[scan.block_id]) acc[scan.block_id] = [];
        acc[scan.block_id].push(scan);
        return acc;
    }, {});

    const toggleAllScansInBlock = (block) => {
        const newCheckedItems = { ...checkedItems };
        scansByBlock[block].forEach(scan => {
            newCheckedItems[scan.scan_id] = true;
        });
        setCheckedItems(newCheckedItems); // Assuming you use a useState hook named `setCheckedItems` for the `checkedItems` state
    };

    return (
        <div className='font-lato overflow-hidden relative max-h-[100vh]' >
            <Branding />
            <div className='mx-6 my-3'>
                <h1 className="text-xl font-bold mr-4 ">Filter & Aggregate All Data for Export:</h1>
                <p className="text-sm text-red-500  mr-4 ">1. Filter scans to export data from based on the below fields.</p>
                <p className="text-sm text-red-500 mb-2 mr-4 ">2. Due to different data formats, FSD (fruitlet) scans cannot be combined with other scan types during a combined spreadsheet download.</p>
                {/* <p className="text-sm text-red-500 mb-6 mr-4 ">4. This means you may combine any scans among buds/blossoms/fruits, but fruitlet scans can only be combined with other fruitlet scans.</p> */}
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="block-select-label">Block</InputLabel>
                            <Select
                                labelId="block-select-label"
                                multiple
                                value={selectedBlock}
                                onChange={(event) => setSelectedBlock(event.target.value)}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {blocks.map((block) => (
                                    <MenuItem key={block} value={block}>
                                        <Checkbox checked={selectedBlock.indexOf(block) > -1} />
                                        <ListItemText primary={block} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="entity-type-select-label">Stage Type</InputLabel>
                            <Select
                                labelId="entity-type-select-label"
                                multiple
                                value={selectedEntityType}
                                onChange={handleEntityTypeChange}
                                renderValue={(selected) => selected.join(', ')}
                            >

                                <MenuItem value="buds">
                                    <Checkbox checked={selectedEntityType.indexOf("buds") > -1} />
                                    <ListItemText primary="Buds" />
                                </MenuItem>
                                <MenuItem value="blossoms">
                                    <Checkbox checked={selectedEntityType.indexOf("blossoms") > -1} />
                                    <ListItemText primary="Blossoms" />
                                </MenuItem>
                                <MenuItem value="early fruitlets">
                                    <Checkbox checked={selectedEntityType.indexOf("early fruitlets") > -1} />
                                    <ListItemText primary="Fruitlets (and FSD scans)" />
                                </MenuItem>
                                <MenuItem value="fruits">
                                    <Checkbox checked={selectedEntityType.indexOf("fruits") > -1} />
                                    <ListItemText primary="Fruits" />
                                </MenuItem>
                                {/* <MenuItem value="trees">
                                    <Checkbox checked={selectedEntityType.indexOf("trees") > -1} />
                                    <ListItemText primary="Trees" />
                                </MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="variety-type-select-label">Variety Type</InputLabel>
                            <Select
                                labelId="variety-type-select-label"
                                multiple
                                value={selectedVarietyType}
                                onChange={(event) => setSelectedVarietyType(event.target.value)}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {varieties.map((variety) => (
                                    <MenuItem key={variety} value={variety}>
                                        <Checkbox checked={selectedVarietyType.indexOf(variety) > -1} />
                                        <ListItemText primary={variety} />
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                format="YYYY/MM/DD"
                                label="Select a Start Date to Filter By"
                                value={startDate}
                                onChange={setStartDate}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                format="YYYY/MM/DD"
                                label="Select an End Date to Filter By"
                                value={endDate}
                                onChange={setEndDate}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                {scansLoading ? (
                    <button
                        className="drop-shadow-md relative bg-gray-400 cursor-not-allowed text-sm text-white font-bold py-1 px-5 rounded-xl my-4"
                        disabled={scansLoading}
                    >
                        Fetching Relevant Scans
                    </button>
                ) : (
                    <button
                        className="drop-shadow-md relative bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-1 px-5 rounded-xl my-4"
                        onClick={handleFetchScans}
                    >
                        Display Relevant Scans
                    </button>
                )}

                <Grid item xs={12}>
                    <div className="overflow-y-auto flex-grow min-h-[5vh] max-h-[45vh] scrollbar-thin scrollbar-visible scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                        {scans.length !== 0 && (
                            <div className="flex bg-gray-200 py-1 justify-between w-full font-bold">
                                <span className="flex-1 ml-8 mr-4">Scan ID</span>
                                <span className="flex-1 ml-2 mr-4">Date Scanned</span>
                                <span className="flex-1">Fruit Stage</span>
                                <span className="flex-1 ml-6">Variety</span>
                                <span className="flex-1 mr-4"># Trees Scanned</span>
                                <span className="flex-1 mr-4">Avg Fruits per Tree</span>
                                <span className="flex-1 mr-8">Avg Fruit Size (mm)</span>
                            </div>
                        )}
                        {scans.length === 0 && <p> No scans to display. Enter a query or try a new query.</p>}

                        {scansLoading && (
                            <div className="flex justify-center items-center">
                                <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={loading} alt='loading...' />
                            </div>
                        )}

                        {Object.keys(scansByBlock).map((block, index) => (
                            <div key={block}>
                                <div className="flex items-center">
                                    <p className="text-lg mr-4 mt-1 ">{`Block ${block}`}</p>
                                    <button
                                        className="bg-blue-500 drop-shadow-md relative text-sm text-white font-bold py-1 px-5 rounded-xl mb-1 mr-2 mt-2"
                                        onClick={() => toggleAllScansInBlock(block)}>
                                        Select All
                                    </button>
                                </div>
                                <List>
                                    {scansByBlock[block].map((scan) => (
                                        <ListItem
                                            style={{ paddingTop: '0px', paddingBottom: '0px', marginTop: '0px' }}
                                            key={scan.scan_id}
                                            dense
                                            button
                                            onClick={() => handleToggle(scan.scan_id)}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    checked={!!checkedItems[scan.scan_id]}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <div className="flex justify-between w-full">
                                                    <span className="flex-1">{scan.scan_id ? `Scan ${scan.scan_id}` : 'N/A'}</span>
                                                    <span className="flex-1">{scan.scan_name ? renderDate(scan.scan_name) : 'N/A'}</span>
                                                    <span className="flex-1">{scan.entity_type || 'N/A'}</span>
                                                    <span className="flex-1">{scan.variety_type || 'N/A'}</span>
                                                    <span className="flex-1">{scan.trees ? `${scan.trees} trees` : 'N/A'}</span>
                                                    <span className="flex-1">
                                                        {scan.fruits_per_tree ? `${scan.fruits_per_tree.toFixed(0)} fruits/tree` : 'N/A'}
                                                    </span>
                                                    <span className="flex-1">
                                                        {scan.fruit_size ? `${scan.fruit_size.toFixed(2)} mm` : 'N/A'}
                                                    </span>
                                                </div>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                                <hr className='border-gray-300 my-1' />
                            </div>
                        ))}
                    </div>
                </Grid>
                <div className='mt-2'>
                    {scans.length > 0 && (
                        <button
                            className={`drop-shadow-md relative text-sm text-white font-bold py-1 px-5 rounded-xl mb-1 mr-2 mt-2 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'}`}
                            onClick={isLoading ? null : () => downloadExcel()}
                            disabled={isLoading}
                        >
                            Get Export Data for Selected Scans
                        </button>
                    )}
                    {scans.length > 0 && (
                        <button
                            className={`drop-shadow-md relative text-sm text-white font-bold py-1 px-5 rounded-xl mb-1 mr-2 mt-2 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'}`}
                            onClick={isLoading ? null : () => downloadIndividualReport()}
                            disabled={isLoading}
                        >
                            Get Reports for Selected Scans
                        </button>
                    )}
                    {scans.length > 0 && (
                        <button
                            className={`drop-shadow-md relative text-sm text-white font-bold py-1 px-5 rounded-xl mb-1 mr-2 mt-2 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'}`}
                            onClick={isLoading ? null : () => downloadAggregateReport()}
                            disabled={isLoading}
                        >
                            Get Aggregate Graphs for Selected Scans
                        </button>
                    )}
                </div>

                <Dialog open={dialog1Open} onClose={handleCloseDialog}>
                    <DialogTitle>Error</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{dialogMessage}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>OK</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={dialog2Open}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Fruitlets can only be combined with other fruitlet (or FSD) scans, and not with scans of other stage types. Please reselect your stage type scan filters.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={dialog3Open}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Trees scans cannot be combined with other entity types. Please reselect your stage type scan filters.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                {isLoading && <div className="pt-1 text-center text-sm text-gray-500">
                    Loading export data...
                    <img style={{ objectFit: "contain", maxWidth: '150px' }} className="mx-auto" src={loading} alt="loading..." />
                </div>}
                {combinedFruitletDF !== null && <FruitletExport scanInfo={scanInfo} data={combinedFruitletDF} />}
            </div>
        </div>
    );
}