import React, { useEffect, useState } from 'react';

const ZoneLegend = ({
    config,
    lowerBound,
    upperBound,
    setLowerBound,
    setUpperBound,
    applyPercentageToTarget,
    convertToBoundOfTarget,
    selectAll,
    treeData,
    StyledTextField
}) => {
    const treeMetric = config.targetVar === "uniTarg" ? "num_buds"
        : config.targetVar === "uniSizeTarg" ? "avg_diam"
            : config.targetVar === "vigorTarg" ? "canopy_area_m2"
                : null;

    const [zonePercentages, setZonePercentages] = useState({ red: 0, green: 0, blue: 0 });

    useEffect(() => {
        const calculateZonePercentages = () => {
            if (!treeData || !treeData.features) return { red: 0, green: 0, blue: 0 };

            let redCount = 0, greenCount = 0, blueCount = 0;
            let validTreeCount = 0;

            treeData.features.forEach(feature => {
                const value = feature.properties[treeMetric];

                if (value !== null && value !== undefined) {
                    validTreeCount++;

                    if (value < applyPercentageToTarget(lowerBound)) {
                        redCount++;
                    } else if (value >= applyPercentageToTarget(lowerBound) && value < applyPercentageToTarget(upperBound)) {
                        greenCount++;
                    } else {
                        blueCount++;
                    }
                }
            });

            return {
                red: validTreeCount > 0 ? (redCount / validTreeCount) * 100 : 0,
                green: validTreeCount > 0 ? (greenCount / validTreeCount) * 100 : 0,
                blue: validTreeCount > 0 ? (blueCount / validTreeCount) * 100 : 0
            };
        };

        setZonePercentages(calculateZonePercentages());
    }, [treeData, lowerBound, upperBound, treeMetric, applyPercentageToTarget]);

    return (
        <div className='flex flex-col items-center'>
            <div className='text-sm mb-1'>{config.caption}</div>
            <div className='w-full h-6 flex mb-1 relative'>
                <div className='flex-1 bg-red-500 flex items-center justify-center'>
                    <span className='text-white text-xs font-bold' style={{ textShadow: '-0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000' }}>Low - {zonePercentages.red.toFixed(2)}%</span>
                </div>
                <div className='flex-1 bg-green-500 flex items-center justify-center'>
                    <span className='text-white text-xs font-bold' style={{ textShadow: '-0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000' }}>Target - {zonePercentages.green.toFixed(2)}%</span>
                </div>
                <div className='flex-1 bg-blue-500 flex items-center justify-center'>
                    <span className='text-white text-xs font-bold' style={{ textShadow: '-0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000' }}>High - {zonePercentages.blue.toFixed(2)}%</span>
                </div>
            </div>
            <div className='flex justify-between w-full'>
                <div className='flex items-center' style={{ width: '44%', justifyContent: 'flex-end' }}>
                    <StyledTextField
                        type="number"
                        value={applyPercentageToTarget(lowerBound)}
                        onChange={(e) => convertToBoundOfTarget(e, setLowerBound)}
                        onFocus={selectAll}
                        InputProps={{
                            endAdornment: <span>{config.unit}</span>,
                        }}
                        size="small"
                        style={{ width: '70px' }}
                        variant="standard"
                    />
                </div>
                <div className='flex items-center' style={{ width: '38%', justifyContent: 'flex-start' }}>
                    <StyledTextField
                        type="number"
                        value={applyPercentageToTarget(upperBound)}
                        onChange={(e) => convertToBoundOfTarget(e, setUpperBound)}
                        onFocus={selectAll}
                        InputProps={{
                            endAdornment: <span>{config.unit}</span>,
                        }}
                        size="small"
                        style={{ width: '70px' }}
                        variant="standard"
                    />
                </div>
            </div>
        </div>
    );
};

export default ZoneLegend;