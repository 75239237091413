import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    FormControlLabel
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { React, useEffect, useState } from 'react';
import { EntityType } from '../common/types';
import { useMapState } from '../context/MapStateContext';

export const EnableDrawControl = ({ drawEnabled, setDrawEnabled }) => {
    return (
        <div
            className='flex items-center px-2 py-2 font-mono bg-white text-xs cursor-pointer text-black border border-gray-900 rounded-lg'
            onClick={() => {
                setDrawEnabled((b) => !b);
            }}
        >
            <div className='flex-shrink-0'>
                <FontAwesomeIcon icon={'fa-solid fa-vector-square'} size='xl' fixedWidth />
            </div>
            <div className='ml-1'>
                Area Selection
                <br />
                {drawEnabled && (
                    <span className='font-mono text-gray-600'>Click the first node to confirm selection. Press delete to cancel selection</span>
                )}
            </div>
        </div>
    );

}

export const MapSettings = ({
    entityType,
    toggleStyle,
    plotType,
    setPlotType,
    controlEnabled,
    setLowerBound,
    setUpperBound,
    adminUser,
    viewTapeSections,
    setViewTapeSections,
    viewZoneMap,
    setViewZoneMap,
    sectionGeojson,
    stageType,
    style,
    view3DMap,
    setView3DMap,
    handlePregenerate,
    fruitType,
    stats,
    showBinLabels,
    setShowBinLabels,
}) => {
    const { plotConfigs, binsPerRow } = useMapState();
    const [visible, setVisible] = useState(true);
    const [activePopup, setActivePopup] = useState(null);

    useEffect(() => {
        if (entityType === 'trees') {
            setPlotType('tree_diam')
            setLowerBound(80)
            setUpperBound(120)
        }
        if (entityType === 'fruits') {
            setPlotType('uniform')
            setLowerBound(60)
            setUpperBound(140)
        }
    }, [entityType])

    const toggleVisible = () => {
        setVisible(v => !v);
    };

    const handleChange = (event) => {
        if (controlEnabled) {
            const value = event.target.value;
            const config = plotConfigs[value];
            if (value === 'color') {
                let lower = (stats.avg_hue + config?.bounds?.[0] + 360) % 180;
                let upper = (stats.avg_hue + config?.bounds?.[1] + 360) % 180;
                if (lower > upper) {
                    upper += 180;
                }
                setLowerBound(lower);
                setUpperBound(upper);
            }
            else if (value === 'canopy_hue') {
                setLowerBound((stats.avg_canopy_hue + config?.bounds?.[0] + 360) % 180);
                setUpperBound((stats.avg_canopy_hue + config?.bounds?.[1] + 360) % 180);
            }
            else {
                setLowerBound(config?.bounds?.[0] ?? 80);
                setUpperBound(config?.bounds?.[1] ?? 120);
            }
            setPlotType(value);
        }
    };

    const handleMouseEnter = (event, id) => {
        setActivePopup(id);
        event.currentTarget.style.position = "relative";
    };

    const handleMouseLeave = (event) => {
        setActivePopup(null);
        event.currentTarget.style.position = "";
    };

    const getVisualizationConfig = (entityType, stageType, fruitType, adminUser, stats) => {
        const exclusions = [];

        // Check for the presence of specific fields and update the exclusion array
        if (!stats?.avg_hue || stats.avg_hue === null || stats.avg_hue === undefined || isNaN(stats.avg_hue)) {
            exclusions.push('color');
        }
        if (!stats?.avg_canopy_area_m2 || stats.avg_canopy_area_m2 === null || stats.avg_canopy_area_m2 === undefined || isNaN(stats.avg_canopy_area_m2)) {
            exclusions.push('tree-vigor');
        }
        if (stats && !stats.ccsa_red_m2 && !stats.ccsa_yellowred_m2 && !stats.ccsa_yellow_m2 && !stats.ccsa_wilted_m2) {
            exclusions.push('rld');
        }

        if (stats && !stats.avg_canopy_hue) {
            exclusions.push('canopy_hue');
        }

        return Object.entries(plotConfigs)
            .filter(([value, config]) => {
                const isCorrectEntityType = !config.entityType || config.entityType === entityType;
                const isCorrectStageType = !config.stageType || config.stageType.includes(stageType);
                const isAdminAllowed = !config.adminOnly || (config.adminOnly && adminUser);
                const isNotExcluded = !exclusions.includes(value);
                return isCorrectEntityType && isCorrectStageType && isAdminAllowed && isNotExcluded;
            })
            .map(([value, config]) => ({
                value,
                label: config.label,
                description: config.description
            }));
    };

    const handleToggleTapeSections = (event) => {
        setViewTapeSections(event.target.checked);
    };

    const handleViewZoneMap = (event) => {
        setViewZoneMap(event.target.checked);
    };

    return (
        <div className='font-lato bg-white opacity-80 rounded-md m-2 px-2 pb-2 pt-1 fixed right-0 top-0 text-lg drop-shadow-md flex flex-row'>
            {visible ? (
                <div className='w-60'>
                    <div className='font-bold text-center'>
                            Heatmap Type
                        <FontAwesomeIcon className='px-2 text-2xl align-middle' icon='fa-solid fa-caret-right' onClick={toggleVisible} />
                    </div>
                    <hr />
                    <form>
                        {getVisualizationConfig(entityType, stageType, fruitType, adminUser, stats).map((e, index) => (
                            <label className='flex flex-row' key={e.value} onMouseEnter={(event) => handleMouseEnter(event, index)}
                                onMouseLeave={handleMouseLeave}>
                                <input type='radio' value={e.value} checked={plotType === e.value} onChange={handleChange} disabled={!controlEnabled} />
                                <span className='ml-2'>{e.label}</span>
                                {activePopup === index && (
                                    <div className="absolute z-10 top-full left-0 mt-2 w-48 rounded-lg shadow-lg bg-white border border-gray-200">
                                        <div className="py-2 px-4 text-sm">{e.description}</div>
                                    </div>
                                )}
                            </label>
                        ))}
                    </form>
                    {adminUser && (
                        <button style={{ maxWidth: '350px' }}
                            className="btn-primary bg-green-500 hover:bg-green-700"
                            onClick={(e) => handlePregenerate()}
                        >
                            Pregenerate Scan (admin)
                        </button>
                    )}
                    <hr />
                    {entityType === EntityType.Fruits && sectionGeojson && sectionGeojson.features.length > 0 && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={viewTapeSections}
                                    onChange={handleToggleTapeSections}
                                    className="m-0"
                                />
                            }
                            label="Ground Truth Sections"
                        />
                    )}

                    <FormControlLabel
                        control={
                            <Switch
                                checked={style === 'mapbox://styles/mapbox/outdoors-v12'}
                                onChange={toggleStyle}
                                size="medium"
                                className="m-0"
                            />
                        }
                        label="Toggle Map Style"
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={view3DMap}
                                onChange={(e) => setView3DMap(e.target.checked)}
                                size="medium"
                                className="m-0"
                            />
                        }
                        label={"View Topography"}
                    />
                    
                    {plotConfigs[plotType]?.enableZone && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={viewZoneMap}
                                    onChange={handleViewZoneMap}
                                    size="medium"
                                    className="m-0"
                                />
                            }
                            label={"View Zone Mode"}
                        />
                    )}
                    {/* {adminUser && binsPerRow && Object.keys(binsPerRow).length > 0 && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showBinLabels}
                                    onChange={(e) => setShowBinLabels(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={
                                <span>
                                    Bins per Row{' '}
                                    {' '}(admin)
                                </span>
                            }
                            className="m-0"
                        />
                    )} */}
                </div>
            ) : (
                <div>
                    <FontAwesomeIcon className='text-2xl align-middle px-2' icon='fa-solid fa-caret-left' onClick={toggleVisible} />
                </div>
            )}
        </div>
    );
};
