import { faInfoCircle, faMapLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { toast } from 'react-hot-toast';
import loading from '../assets/loading.gif';
import { FruitType, StageType } from '../common/types';
import * as Constants from '../constants/constants';
import { iconNames } from '../constants/iconNames';
import { useMapState } from '../context/MapStateContext';
import { BlockTimeline } from './BlockTimeline';
import { Branding, ImageDisplayModes } from './Branding';
import { Card, OrchardCard } from './Cards';
import { Charts } from './ChartCard';
import { DownloadCard } from './DownloadCard';
import { HarvestEstimateCard } from './HarvestEstimateCard';
import { OrchardView } from './OrchardView';
import { PackoutCard } from './PackoutCard';
import { PolygonStats } from './PolygonStats';
import { ScanViewStats } from './ScanViewStats';


export const MetricItem = ({ metric, label, icon, units, infoTip }) => (
    <div className='flex flex-row justify-between items-baseline mt-1'>
        <span className='flex flex-row'>
            {icon ? <FontAwesomeIcon size='lg' icon={icon} fixedWidth /> : null} {' '}
            <span className={'text-l ' + (icon ? ' pl-2' : '')}>{label}</span>
        </span>
        <span className='pl-2 text-l flex items-center'>
            {metric} {units}
            {infoTip && (
                <Tooltip title={infoTip} arrow>
                    <span className="ml-1">
                        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                    </span>
                </Tooltip>
            )}
        </span>
    </div>
);

function capitalizeFirstLetter(string) {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const LoadView = ({ blockName, handlePressBack }) => (
    <>
        <Card title='Load View'>
            <p className='pb-1 text-xs italic text-slate-500 pt-2 text-left text-gray-500'>    A view of your entire block. Statistics are displayed by individual scans taken within this block.</p>
            <button className='btn-primary my-2 absolute top-1 right-3' onClick={handlePressBack}> Back </button>
            <></>
            {blockName ?
                <div className="flex flex-col items-center">
                    <p className='pb-1 text-xs italic text-slate-500 pt-1 text-left text-gray-500'>Updating all scan data...</p>
                    <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={loading} alt='loading...' />
                </div>
                : <p> No scans to show</p>}
        </Card>
    </>
);

const BlockView = ({ blockView, handleIconClick, isExpanded, setIsExpanded, timelineData, currentScanId, handlePressBack, blockInfo }) => (

    <>
        <OrchardCard blockView={blockView} isExpanded={isExpanded} title='Block View' description={' A view of your entire block. Statistics are displayed by individual scans taken within this block.'}>
            <button className='btn-primary my-2 absolute top-0 right-3' onClick={handlePressBack}> Back </button>
            <></>
            {blockInfo && blockInfo.block_id ? (
                <MetricItem key={Constants.BLOCK_ID} icon={iconNames[Constants.BLOCK_ID]} metric={blockInfo.block_name.toLocaleString('en-US')} label={'Block ID'} />
            ) : null}
            {blockInfo && blockInfo.acreage ? (
                <MetricItem key={"Block Acreage"} icon={faMapLocationDot} metric={blockInfo.acreage.toFixed(1)} label={"Acreage"} units={'acres'} />
            ) : null}
            <h1 className='text-center font-bold text-xl font-lato'> Scan Timeline</h1>
            <hr className='border-gray-300 mt-1' />

            {timelineData ?
                <>
                    <BlockTimeline
                        timelineData={timelineData}
                        handleIconClick={handleIconClick}
                        currentScanId={currentScanId}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                    >
                    </BlockTimeline>
                </> : null
            }

            {!isExpanded ?
                <button className='mx-4 btn-primary mb-2' onClick={() => setIsExpanded(true)}> Show full timeline </button> : null}
        </OrchardCard>
    </>
);


const ScanNotes = ({ scanInfo, adminUser, currentScanId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { rowStats, setRowStats, numTargetRows, setNumTargetRows } = useMapState();
    const [displayedRows, setDisplayedRows] = useState([]);
    const [orderBy, setOrderBy] = useState('row_number');
    const [order, setOrder] = useState('desc');

    useEffect(() => {
        // Further ideas for this feature:
        // - highlight the rows in the map
        // - Copy coordinates of the rows / export somehow
        // - Conditionally render based on type of scan
        // BUG - column doesnt sort correcly on first click
        const fetchAllRowStats = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get('/util/get_sample_rows', {
                    params: {
                        scan_id: scanInfo.scan_id
                    }
                });
                setRowStats(response.data.rows);
                console.log('rowStats', response.data.rows);
            } catch (error) {
                toast.error('Error fetching row data');
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        };
        if (currentScanId) {
            fetchAllRowStats();
        }
    }, [currentScanId]);

    const handleRowsChange = (event) => {
        const value = parseInt(event.target.value);
        setNumTargetRows(value);
        setDisplayedRows(rowStats
            ?.sort((a, b) => b.similarity - a.similarity) // decreasing order
            .slice(0, value)
            .sort((a, b) => a.row_number - b.row_number) || []);
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        
        const sortedRows = [...displayedRows].sort((a, b) => {
            if (order === 'asc') {
                return a[property] < b[property] ? -1 : 1;
            } else {
                return b[property] < a[property] ? -1 : 1;
            }
        });
        setDisplayedRows(sortedRows);
    };

    return (
        <Card title='Scan Info' showButton description={'Additional details about the scan.'}>
            {scanInfo.technician_name ? (
                <MetricItem key={"Technician Name"} metric={scanInfo.technician_name} label={"Scanned By: "} />
            ) : null}
            {scanInfo.tree_structure ? (
                <MetricItem key={"Trellis Type"} metric={scanInfo.tree_structure} label={"Trellis Type: "} />
            ) : null}
            {scanInfo.avg_tree_spacing ? (
                <MetricItem key={"Tree Spacing"} metric={scanInfo.avg_tree_spacing.toFixed(2)} label={"Reported tree spacing: "} units={"m"} />
            ) : null}
            {scanInfo.rows_in_block ? (
                <MetricItem key={"Rows in Block"} metric={scanInfo.rows_in_block} label={"Reported total rows in block: "} units={"rows"} />
            ) : null}
            {scanInfo.rows_scanned ? (
                <MetricItem key={"Rows Scanned"} metric={scanInfo.rows_scanned} label={"Reported rows scanned: "} units={"rows"} />
            ) : null}
            
            {adminUser && (
                <details className="mt-3">
                    <summary className="text-gray-600 hover:text-gray-800 cursor-pointer">
                    Calculate best subset of rows (admin) [WIP]
                </summary>
                    <Typography variant="caption" className="text-gray-500 mt-1 block">
                        Find the closest subset of rows compared against the scan&apos;s fruit diameter distribution stats
                    </Typography>
                    <div className="mt-2">
                        <TextField
                        size="small"
                        type="number"
                        label="Number of sample rows"
                        variant="outlined"
                        fullWidth
                        value={numTargetRows}
                        onChange={handleRowsChange}
                        InputProps={{
                            inputProps: { 
                                min: 1,
                                max: scanInfo.rows_scanned || 999
                            }
                        }}
                    />
                    {isLoading ? (
                        <div className="flex justify-center mt-4">
                            <CircularProgress size={24} />
                        </div>
                    ) : rowStats && (
                        <TableContainer className="mt-2">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'row_number'}
                                                direction={orderBy === 'row_number' ? order : 'asc'}
                                                onClick={() => handleSort('row_number')}
                                            >
                                                Row
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel
                                                active={orderBy === 'similarity'}
                                                direction={orderBy === 'similarity' ? order : 'asc'}
                                                onClick={() => handleSort('similarity')}
                                            >
                                                Similarity Score
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayedRows.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.row_number}</TableCell>
                                            <TableCell align="right">{row.similarity.toFixed(3)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    </div>
                </details>
            )}
        </Card>
    );
};

export const Sidebar = ({ setBlockName, setScanInfo, orchard, setSectionGeojson, plotType, selectedVarieties, downloadFormat, setDownloadFormat, setPlotType, polygonStats, addToSidebar, chartType, setChartType, mapInstance, scanLoading, setScanLoading, blockScanMap, entity, setProgress, progress, currentScanId, setCurrentScanId, stats, setSidebarViewMode, sidebarViewMode, setBlockView, blockName, blockView, enableRowFilter, stageType, blockInfo, setBlockInfo, setTreeViewLat, setTreeViewLong, sidebarWidth, setSidebarWidth, scanInfo, adminUser, treeData, fruitType, qualifyingBlocks }) => {
    const [imgDisplayMode, setImgDisplayMode] = useState(ImageDisplayModes.FULL_A);
    const [isExpanded, setIsExpanded] = useState(false);
    const [timelineData, setTimelineData] = useState([]);

    const sidebarRef = useRef(null);
    const dragHandleRef = useRef(null);

    useEffect(() => {
        (async () => {
            console.log('blockName', blockName);
            if (blockName) {
                try {
                    const response = await axios.get('/util/block_timeline', {
                        params: { 'block_id': blockName },
                    });

                    if (response.status === 200) {
                        setTimelineData(response.data);
                    }
                } catch (error) {
                    toast.error('Error fetching block data, hit back to return to the orchard view.');
                }
            }
        })();
    }, [blockName]);

    const handleIconClick = (scanName, scanId) => {
        setCurrentScanId(scanId);
        if (isExpanded) {
            setScanLoading(true)
        }
        setIsExpanded(false);
        setTimeout(() => {
            if (isExpanded) {
                setScanLoading(false)
            }
        }, 4000);
    };

    const handleChange = (event) => {
        setChartType(event.target.value);
    };

    const handleDownloadChange = (event) => {
        setDownloadFormat(event.target.value);
    };

    const handlePressBack = () => {
        // TODO: Some of these are redundant, see blockName useEffect in App.tsx
        setBlockView(false);
        setPlotType('');
        setSidebarViewMode('Orchard');
        setCurrentScanId(null);
        setBlockName(null);
        setProgress(100);
        setTimelineData([]);
        setIsExpanded(false);
        setBlockInfo(null);
        setSectionGeojson(null);
        setScanInfo(null);
    }

    const handleDrag = (e, { deltaX }) => {
        setSidebarWidth(prevWidth => {
            const newWidth = prevWidth + deltaX;
            const maxWidth = window.innerWidth - 340;
            return newWidth >= 340 ? (newWidth <= maxWidth ? newWidth : maxWidth) : 340;
        });
    };

    const ChartView = ({ chartType, setChartType }) => {

        if (blockView || sidebarViewMode === 'Block') {

            return stats === null ? (
                <div className='grid h-screen place-items-center'>
                    <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={loading} alt='loading...' />
                </div>
            ) : (
                <Charts
                    entityType={entity}
                    currentScanId={currentScanId}
                    handleChange={handleChange}
                    chartType={chartType}
                    blockId={blockName}
                    sidebarViewMode={sidebarViewMode}
                    selectedVarieties={selectedVarieties}
                    enableRowFilter={enableRowFilter}
                    stageType={stageType}
                    plotType={plotType}
                    setChartType={setChartType}
                    scanInfo={scanInfo}
                />
            );
        }
        return null;
    };

    const toggleImgDisplayMode = (mode) => {
        setImgDisplayMode((mode + 1) % 6);
    }

    return (
        <div
            ref={sidebarRef}
            style={{ width: sidebarWidth, minWidth: '340px' }}
            className='font-lato col-span-1 flex flex-col h-screen bg-gray-300 relative z-30 border-x-orchardGreen border-x-2'>
            <Branding imgDisplayMode={imgDisplayMode} toggleImgDisplayMode={toggleImgDisplayMode} scanInfo={scanInfo} />
            <div className={blockView && !isExpanded ? 'overflow-y-auto flex-grow h-0' : 'overflow-hidden flex-grow h-0'}>
                <div className='flex flex-col h-full py-3 space-y-3'>
                    {(progress === 100 && !scanLoading) ? (
                        <>
                            {blockView === true && (
                                <BlockView className='text-sm'
                                    blockView={blockView}
                                    handlePressBack={handlePressBack}
                                    currentScanId={currentScanId}
                                    timelineData={timelineData}
                                    handleIconClick={handleIconClick}
                                    isExpanded={isExpanded}
                                    setIsExpanded={setIsExpanded}
                                    blockInfo={blockInfo}
                                />
                            )}
                            {addToSidebar === true && !isExpanded && blockView === true && polygonStats !== null && (
                                <Card title={`Area Selection ${capitalizeFirstLetter(entity)} Scan Statistics`} showButton defaultOpen={true} description={'View statistics about the selected area of the scan.'}>
                                    <PolygonStats entityType={entity} stageType={stageType} stats={polygonStats} />
                                </Card>
                            )}
                            
                            {(blockView || sidebarViewMode === 'Block') ? (
                                stats === null ? (
                                    <div className='grid h-screen place-items-center'>
                                        <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={loading} alt='loading...' />
                                    </div>
                                ) : (
                                    <>
                                        {!isExpanded && stats &&
                                            <ScanViewStats
                                                entityType={entity}
                                                stats={stats}
                                                stageType={stageType}
                                            />
                                        }
                                        {!isExpanded && scanInfo && 
                                            <ScanNotes scanInfo={scanInfo} adminUser={adminUser} currentScanId={currentScanId} />
                                        }
                                    </>
                                )
                            ) : (
                                <OrchardView className='text-sm'
                                    orchard={orchard}
                                    blockScanMap={blockScanMap}
                                    blockView={blockView}
                                    setBlockView={setBlockView}
                                    setTreeViewLat={setTreeViewLat}
                                    setTreeViewLong={setTreeViewLong}
                                    setSidebarViewMode={setSidebarViewMode}
                                    setBlockName={setBlockName}
                                />
                            )}

                            {scanInfo && fruitType === FruitType.Apple && stageType === StageType.Fruit && blockView === true && (
                                <HarvestEstimateCard
                                    stats={stats}
                                    scanInfo={scanInfo}
                                    qualifyingBlocks={qualifyingBlocks}
                                    adminUser={adminUser}
                                    blockInfo={blockInfo}
                                    treeData={treeData}
                                />
                            )}

                            {fruitType === FruitType.Apple && stageType === StageType.Fruit && blockView === true && (
                                <PackoutCard stats={stats} />
                            )}
                            {stats && !isExpanded && (
                                <ChartView chartType={chartType}
                                    setChartType={setChartType} />
                            )}

                            {stats && scanInfo && !isExpanded && blockView === true && (
                                <DownloadCard
                                    plotType={plotType}
                                    selectedVarieties={selectedVarieties}
                                    entityType={entity}
                                    handleDownloadChange={handleDownloadChange}
                                    downloadFormat={downloadFormat}
                                    mapInstance={mapInstance}
                                    blockName={blockName}
                                    treeData={treeData}
                                    stats={stats}
                                    scanInfo={scanInfo}
                                    currentScanId={currentScanId}
                                />
                            )}
                        </>
                    ) : progress !== 100 && scanLoading ? (
                        <LoadView blockName={blockName} handlePressBack={handlePressBack} />
                    ) : null}
                    <div className='text-lg py-5' />
                </div>
            </div>
            <Draggable
                axis='x'
                handle='.sidebar-handle'
                onDrag={handleDrag}
                position={{ x: 0, y: 0 }}
                nodeRef={dragHandleRef}
            >
                <div
                    ref={dragHandleRef}
                    className='sidebar-handle absolute top-0 right-0 w-2 h-full cursor-col-resize'
                />
            </Draggable>
        </div>
    )
}