import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import axios from 'axios';

export const SeasonSelector = ({ currentSeason, setCurrentSeason, availableSeasons }) => {
    const handleSeasonChange = async (event) => {
        const newSeason = event.target.value;
        try {
            await axios.post('/util/update_season', { season: newSeason });
            setCurrentSeason(newSeason);
        } catch (error) {
            console.error('Failed to update season:', error);
        }
    };

    return (
        <div className="flex flex-row justify-between items-baseline mt-1" data-testid="season-selector-container">
            <div className="flex flex-row">
                <FontAwesomeIcon icon={faCalendar} size="lg"/>
                <Typography variant="body1" className="text-l pl-2">Season </Typography>
            </div>
            <FormControl size="small" sx={{ width: '80px' }} >
                <Select
                    value={currentSeason}
                    onChange={handleSeasonChange}
                    className="bg-white"
                    data-testid="season-selector"
                    sx={{ 
                        height: '30px',
                        '.MuiOutlinedInput-input': { 
                            padding: '4px 10px'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        }
                    }}
                >
                    {availableSeasons.map((season) => (
                        <MenuItem key={season} value={season} data-testid={`season-option-${season}`}>{season}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};