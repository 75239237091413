import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-hot-toast';

export const FeedbackForm = ({ rawScanName }) => {
    const [feedback, setFeedback] = useState("");

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            await axios.post('/user/send_feedback', { message: feedback, scan: rawScanName });
            toast.success('Feedback sent!');
            setFeedback("");
        } catch (error) {
            console.error(error);
            toast.error('Failed to submit feedback.');
        }
    };

    return (
        <div className="flex flex-col items-end w-full">
            <TextField
                autoFocus
                margin="dense"
                id="feedback"
                label="What worked well? What could be improved?"
                type="text"
                fullWidth
                value={feedback}
                onChange={handleFeedbackChange}
            />
            <Button onClick={handleSubmit} color="primary" style={{ marginTop: '8px' }}>
                Submit
            </Button>
        </div>
    );
};
