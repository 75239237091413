import { Menu as MenuIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { getTerminology } from '../i18n';

export const ImageDisplayModes = {
    HIDE: 0,
    MIDDLE: 5,
    MID_A: 2,
    FULL: 3,
    FULL_A: 4,
    SECRET: 1
}

export const Branding = ({ imgDisplayMode, toggleImgDisplayMode, adminUser, fruitType }) => {
    const handleAdmin = () => {
        window.location.pathname = '/admin'
    }
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const menuRef = useRef(null);

    useEffect(() => {
        if (imgDisplayMode === ImageDisplayModes.SECRET) {
            handleAdmin();
        }
    }, [imgDisplayMode]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Determine fruit color based on scanInfo
    const fruitColor = (() => {
        if (!fruitType) return '#ff0333'; 
        
        switch (fruitType) {
            case 'Grapes':
                return '#B00080'; // Purple
            case 'Blueberries':
                return '#4141C8'; // Blue
            case 'Cherries':
                return '#990000'; // Red
            default:
                return '#ff0333'; // Red
        }
    })();

    return (
        <div className='relative' ref={menuRef}>
            <div
                id='branding'
                className='flex flex-col justify-between bg-orchardGreen font-lato text-white'
            >
                <div className='flex flex-row justify-center my-1 mx-3.5'>
                    <div className='flex flex-row items-center'>
                        <Logo
                            width={80}
                            height={80}
                            onClick={() => { toggleImgDisplayMode(imgDisplayMode); }}
                            style={{
                                '--fruit-color': fruitColor,
                            }}
                        />
                        <div className='flex flex-col justify-center items-center'>
                            <h1 className='font-bold text-3xl mt-2'>FruitScope OS</h1>
                            {
                                imgDisplayMode !== ImageDisplayModes.HIDE && imgDisplayMode !== ImageDisplayModes.SECRET ?
                                    <h2 className='text-sm text-center'>{(
                                        (imgDisplayMode === ImageDisplayModes.MID_A) || (imgDisplayMode === ImageDisplayModes.FULL_A)) ? 'made ' : ''}
                                        {(imgDisplayMode === ImageDisplayModes.FULL) || (imgDisplayMode === ImageDisplayModes.FULL_A) ? 'by' : 'from'} {getTerminology('Orchard')} Robotics</h2>
                                    :
                                    null
                            }
                            {
                                imgDisplayMode === ImageDisplayModes.SECRET ?
                                    <h2 className='text-sm text-center'>
                                        admin </h2>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    {/* {adminUser ?
                        <IconButton
                            color="inherit"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            size="large"
                            edge="end"
                            aria-label="menu"
                            disableRipple
                            className="focus:outline-none mr-8"
                            data-testid="menu-button"
                        >
                            <MenuIcon />
                        </IconButton>
                    :
                        null
                    } */}
                </div>
            </div>
            
            {/* {showAlert && (

                <Alert 
                    severity="warning" 
                    className="text-xs bg-amber-50 text-amber-900 border-none"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => setShowAlert(false)}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                        Scheduled maintenance 1/29 8pm - 11pm PT
                    </Alert>
            )} */}

            {/* Animated Menu */}
            {/* <div 
                className={`absolute w-full bg-white text-gray-800 transition-all duration-300 ease-in-out overflow-hidden z-10 ${
                    isMenuOpen ? 'max-h-[500px]' : 'max-h-0'
                }`}
            >   
            </div> */}
        </div>

    );
}