/* Anything shared between sidebar & mapbox goes here */
import convert from 'convert-units';
import mapboxgl from 'mapbox-gl';
import React, { createContext, MutableRefObject, useContext, useRef, useState } from 'react';
import { EntityType, StageType } from '../common/types';

interface SelectedRLDOptions {
  ccsa_red_m2: boolean;
  ccsa_yellowred_m2: boolean;
  ccsa_yellow_m2: boolean;
  ccsa_wilted_m2: boolean;
}

interface MapStateContextType {
  lowerBound: number;
  setLowerBound: React.Dispatch<React.SetStateAction<number>>;
  upperBound: number;
  setUpperBound: React.Dispatch<React.SetStateAction<number>>;
  viewZoneMap: boolean;
  setViewZoneMap: React.Dispatch<React.SetStateAction<boolean>>;
  map: MutableRefObject<mapboxgl.Map | null>;
  plotConfigs: {
    [key: string]: any;
  };
  isImperial: boolean;
  setIsImperial: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRLDOptions: SelectedRLDOptions;
  setSelectedRLDOptions: React.Dispatch<React.SetStateAction<SelectedRLDOptions>>;
  binsPerRow: { [key: number]: number };
  setBinsPerRow: React.Dispatch<React.SetStateAction<{ [key: number]: number }>>;
  rowStats: { [key: number]: number };
  setRowStats: React.Dispatch<React.SetStateAction<{ [key: number]: number }>>;
  numTargetRows: number;
  setNumTargetRows: React.Dispatch<React.SetStateAction<number>>;
}

const MapStateContext = createContext<MapStateContextType | undefined>(undefined);

export const MapStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [lowerBound, setLowerBound] = useState(60);
  const [upperBound, setUpperBound] = useState(140);
  const [viewZoneMap, setViewZoneMap] = useState(false);
  const [isImperial, setIsImperial] = useState(true);
  const map = useRef<mapboxgl.Map | null>(null);

  // For RLD Heatmap
  const [selectedRLDOptions, setSelectedRLDOptions] = useState<SelectedRLDOptions>({
    ccsa_red_m2: true,
    ccsa_yellowred_m2: false,
    ccsa_yellow_m2: false,
    ccsa_wilted_m2: false
  });

  // Bins per Row Calculation
  const [binsPerRow, setBinsPerRow] = useState<{ [key: number]: number }>({});

  // For Row Distribution Similarity Feature
  const [rowStats, setRowStats] = useState<{ [key: number]: number }>({});
  const [numTargetRows, setNumTargetRows] = useState<number>(0);


  // TODO: add the stat for each config and replace any logic with "__targ" in mapbox

  const plotConfigs = {
    'tree_diam': {
      caption: 'Trunk Diameter',
      targetVar: 'treeDiamTarg',
      feature: 'width',
      unit: 'in',
      legend: 'standard',
      enableZone: true,
      label: 'Trunk Diameter',
      description: "Heatmap that shows trunk diameter per tree",
      entityType: EntityType.Trees,
      adminOnly: false,
      bounds: [80, 120],
      stat: ''
    },
    'spacing': {
      caption: 'Trunk Spacing',
      targetVar: 'spacingTarg',
      feature: 'tree_spacing',
      unit: 'ft',
      legend: 'standard',
      enableZone: true,
      label: 'Trunk Spacing',
      description: "Heatmap that shows the spacing between trees",
      entityType: EntityType.Trees,
      adminOnly: false,
      bounds: [80, 120],
      stat: ''
    },
    'uniform': {
      caption: 'Fruits per Tree',
      feature: 'num_buds',
      targetVar: 'uniTarg',
      unit: 'fruits',
      legend: 'standard',
      enableZone: true,
      label: 'Fruit Count',
      description: "Heatmap that shows the average fruit count per tree",
      entityType: EntityType.Fruits,
      stageType: [
        StageType.Bud,
        StageType.SilverTip,
        StageType.GreenTip,
        StageType.TightCluster,
        StageType.Pink,
        StageType.KingBloom,
        StageType.FullBloom,
        StageType.PetalFall,
        StageType.Fruit
      ],
      adminOnly: false,
      bounds: [60, 140],
      stat: ''
    },
    'size': {
      caption: 'Fruit Size per Tree',
      targetVar: 'uniSizeTarg',
      feature: 'avg_diam',
      unit: 'mm',
      legend: 'standard',
      enableZone: true,
      label: 'Fruit Size',
      description: "Heatmap that shows the average fruit size per tree",
      entityType: EntityType.Fruits,
      stageType: [StageType.EarlyFruitSet, StageType.Fruit],
      adminOnly: false,
      bounds: [95, 105],
      stat: ''
    },
    'color': {
      caption: 'Fruit Color',
      targetVar: 'colorTarg',
      feature: 'avg_fruit_hue',
      unit: '',
      legend: 'hue',
      enableZone: false,
      label: <span>Fruit Color<sup className="text-red-500 text-xs font-bold ml-1">New!</sup></span>,
      description: "Heatmap that shows the average color of fruits per tree",
      entityType: EntityType.Fruits,
      adminOnly: false,
      bounds: [-25, 25],
      stat: 'avg_hue'
    },
    'tree-vigor': {
      caption: 'Canopy Area',
      targetVar: 'vigorTarg',
      feature: 'canopy_area_m2',
      unit: 'm\u00B2',
      legend: 'standard',
      enableZone: true,
      label: 'Tree Vigor',
      description: "Heatmap that shows the canopy area of a tree",
      adminOnly: false,
      bounds: [70, 130],
      stat: ''
    },
    'canopy_hue': {
      caption: 'Canopy Hue',
      targetVar: '',
      feature: 'canopy_hue',
      unit: '',
      legend: 'hue',
      enableZone: false,
      label: <span>Canopy Hue (admin)<sup className="text-red-500 text-xs font-bold ml-1">New!</sup></span>,
      description: "Heatmap that shows the canopy hue of a tree",
      entityType: EntityType.Fruits,
      adminOnly: true,
      bounds: [-25, 25],
      stat: 'avg_canopy_hue'
    },
    'rld': {
      caption: 'RLD',
      targetVar: 'rldTarg',
      feature: 'rld',
      unit: isImperial ? 'ft²' : 'm²',
      legend: 'RLD',
      enableZone: false,
      label: 'Red Leaf Disease (RLD) (admin)',
      description: "Heatmap that shows red leaf disease (RLD) per tree",
      entityType: EntityType.Fruits,
      adminOnly: true,
      convert: (value: number) => {
        return isImperial
          ? convert(value).from('m2').to('ft2')
          : convert(value).from('ft2').to('m2');
      },
      bounds: [0, 150],
      stat: ''
    },
    'block_audit': {
      caption: 'Block Audit',
      targetVar: 'blockAuditTarg',
      feature: 'block_audit',
      unit: '',
      legend: '',
      enableZone: false,
      label: 'Block Audit (admin)',
      description: "Interface for modifying blocks.",
      adminOnly: true,
      stat: ''
    },
    'row_audit': {
      caption: 'Row Audit',
      targetVar: 'rowAuditTarg',
      feature: 'row_audit',
      unit: '',
      legend: '',
      enableZone: false,
      label: 'Row Audit (admin)',
      description: "Interface for setting rows.",
      adminOnly: true,
      stat: ''
    },
    'trunk_audit': {
      caption: 'Trunk Audit',
      targetVar: 'trunkAuditTarg',
      feature: 'trunk_audit',
      unit: '',
      legend: '',
      enableZone: false,
      label: 'Trunk Audit (admin)',
      description: "Interface for modifying trunks.",
      // entityType: EntityType.Trees,
      adminOnly: true,
      stat: ''
    },
    'master_trunk_audit': {
      caption: 'Master Trunk Audit',
      targetVar: 'masterTrunkAuditTarg',
      feature: 'master_trunk_audit',
      unit: '',
      legend: '',
      enableZone: false,
      label: 'Master Trunk Audit (admin)',
      description: "Interface for modifying trunks.",
      // entityType: EntityType.Trees,
      adminOnly: true,
      stat: ''
    }
  };

  return (
    <MapStateContext.Provider value={{
      lowerBound,
      setLowerBound,
      upperBound,
      setUpperBound,
      viewZoneMap,
      setViewZoneMap,
      map,
      plotConfigs,
      isImperial,
      setIsImperial,
      selectedRLDOptions,
      setSelectedRLDOptions,
      binsPerRow,
      setBinsPerRow,
      rowStats,
      setRowStats,
      numTargetRows,
      setNumTargetRows
    }}>
      {children}
    </MapStateContext.Provider>
  );
};

export const useMapState = () => {
  const context = useContext(MapStateContext);
  if (context === undefined) {
    throw new Error('useMapState must be used within a MapStateProvider');
  }
  return context;
};
