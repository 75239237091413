import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

/**
 * @typedef {Object} Bound
 * @property {number} lower - The lower hue value (0-180)
 * @property {number} upper - The upper hue value (0-180)
 * 
 * @typedef {Object} Annotation
 * @property {number} degree - The degree position (0-360)
 * @property {string} caption - The text to display at the degree position
 * 
 * @param {Object} props
 * @param {'sm' | 'md' | 'lg'} props.size - Canvas size (sm: 200px, md: 350px, lg: 500px)
 * @param {Bound[]} props.bounds - Array of bounds with lower and upper values
 * @param {function} props.onBoundsChange - Callback when bounds are updated
 * @param {Annotation[]} [props.annotations=[]] - Optional array of degree annotations
 */
const ColorWheel = ({
    size,
    bounds,
    onBoundsChange,
    annotations = [],
    disabled = false,
}) => {
    const canvasRef = useRef(null);
    const isDragging = useRef(false);
    const activeHandle = useRef(null);
    const activeBoundIndex = useRef(null);

    const getConfig = () => {
        switch (size) {
            case 'sm':
                return {
                    canvasSize: 200,
                    fontSize: '12px',
                    radiusPadding: 30,
                    handleRadius: 8,
                    lineWidth: 2,
                    cursorHoverOffset: 0
                };
            case 'md':
                return {
                    canvasSize: 350,
                    fontSize: '14px',
                    radiusPadding: 35,
                    handleRadius: 10,
                    lineWidth: 4,
                    cursorHoverOffset: 10
                };
            case 'lg':
                return {
                    canvasSize: 500,
                    fontSize: '16px',
                    radiusPadding: 50,
                    handleRadius: 12,
                    lineWidth: 8,
                    cursorHoverOffset: 15
                };
        }
    };

    const config = getConfig();
    const devicePixelRatio = window.devicePixelRatio;

    useEffect(() => {
        console.log("BOUNDS", bounds);
        drawCircularSlider();
    }, [bounds]);


    // Convert angle to hue (0-180)
    const angleToHue = (angle) => {
        // Normalize angle to 0-360 range
        angle = (angle + 360) % 360;
        // Convert to 0-180 range
        return angle / 2;
    };

    // Convert hue to angle (0-360)
    const hueToAngle = (hue) => {
        return hue * 2;
    };

    // Convert mouse position to angle
    const getAngle = (x, y, centerX, centerY) => {
        const deltaX = x - centerX;
        const deltaY = y - centerY;
        let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
        // Convert from [-180, 180] to [0, 360]
        return (angle + 360) % 360;
    };

    const drawCircularSlider = () => {
        const devicePixelRatio = window.devicePixelRatio;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const width = canvas.width / devicePixelRatio;
        const height = canvas.height / devicePixelRatio;
        const centerX = width / 2;
        const centerY = height / 2;
        const radius = Math.min(width, height) / 2 - config.radiusPadding;

        ctx.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);

        // Clear canvas
        ctx.clearRect(0, 0, width, height);

        // Draw color wheel
        for (let angle = 0; angle < 360; angle++) {
            const startAngle = (angle - 1) * Math.PI / 180;
            const endAngle = angle * Math.PI / 180;
            
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius, startAngle, endAngle);
            ctx.closePath();
            
            ctx.fillStyle = `hsl(${angle}, 100%, 50%)`;
            ctx.fill();
        }
        console.log("Bounds", bounds);
        // Draw highlighted arcs for all bounds
        bounds.forEach((bound, index) => {
            const startAngle = hueToAngle(bound.lower) * Math.PI / 180;
            const endAngle = hueToAngle(bound.upper) * Math.PI / 180;
            
            // Draw outer highlight
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius + 3, startAngle, endAngle);
            ctx.strokeStyle = '#000000';
            ctx.lineWidth = 5;
            ctx.stroke();

            // Add grade number if there are multiple bounds
            if (bounds.length > 1) {
                // Calculate middle angle for text placement
                const middleAngle = (startAngle + endAngle) / 2;
                const textRadius = radius + 15; // Position text slightly outside the highlight
                const textX = centerX + Math.cos(middleAngle) * textRadius;
                const textY = centerY + Math.sin(middleAngle) * textRadius;

                // Draw grade number
                ctx.font = `bold ${config.fontSize} Arial`;
                ctx.fillStyle = '#000000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(`${index + 1}`, textX, textY);
            }
            
            // Draw handles and connecting lines
            [bound.lower, bound.upper].forEach((value) => {
                const angle = hueToAngle(value) * Math.PI / 180;
                const x = centerX + Math.cos(angle) * radius;
                const y = centerY + Math.sin(angle) * radius;
                // Draw connecting line
                ctx.beginPath();
                ctx.moveTo(centerX, centerY);
                ctx.lineTo(x, y);
                ctx.strokeStyle = '#ffffff';
                ctx.lineWidth = 2;
                ctx.stroke();
                
                // Draw handle
                ctx.beginPath();
                ctx.arc(x, y, config.handleRadius, 0, 2 * Math.PI);
                ctx.fillStyle = `hsl(${value * 2}, 100%, 50%)`;
                ctx.fill();
                ctx.strokeStyle = '#fff';
                ctx.lineWidth = 2;
                ctx.stroke();
            });
        });

        // Add degree annotations
        ctx.font = `${config.fontSize} Arial`;
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        
        // 0° (right)
        ctx.fillText('0°', centerX + radius + 14, centerY);
        
        // 90° (bottom)
        ctx.fillText('90°', centerX, centerY + radius + 14);
        
        // 180° (left)
        ctx.fillText('180°', centerX - radius - 14, centerY);
        
        // 270° (top)
        ctx.fillText('270°', centerX, centerY - radius - 14);

        // Add custom annotations
        annotations.forEach(({ degree, caption }) => {
            const angle = degree * Math.PI / 180;
            const lineStartRadius = 0; // Start from center
            const lineEndRadius = radius + 10; // Extend slightly outside
            const textRadius = radius + 32; // Position text outside

            // Draw dotted line
            ctx.beginPath();
            ctx.setLineDash([3, 3]); // Create dotted line
            ctx.moveTo(
                centerX + Math.cos(angle) * lineStartRadius,
                centerY + Math.sin(angle) * lineStartRadius
            );
            ctx.lineTo(
                centerX + Math.cos(angle) * lineEndRadius,
                centerY + Math.sin(angle) * lineEndRadius
            );
            ctx.strokeStyle = '#666666';
            ctx.lineWidth = 1;
            ctx.stroke();
            ctx.setLineDash([]); // Reset line style

            // Draw caption
            const textX = centerX + Math.cos(angle) * textRadius;
            const textY = centerY + Math.sin(angle) * textRadius;
            ctx.font = `${config.fontSize} Arial`;
            ctx.fillStyle = '#666666';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(caption, textX, textY);
        });
    };

    const handleMouseDown = (e) => {
        if (disabled) return;
        const devicePixelRatio = window.devicePixelRatio;

        const canvas = canvasRef.current;

        const width = canvas.width / devicePixelRatio;
        const height = canvas.height / devicePixelRatio;
        
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const centerX = width / 2;
        const centerY = height / 2;
        
        const clickAngle = getAngle(x, y, centerX, centerY);
        const clickHue = angleToHue(clickAngle);
        
        // Find closest handle among all bounds
        let minDiff = Infinity;
        
        bounds.forEach((bound, boundIndex) => {
            ['lower', 'upper'].forEach(handleType => {
                const value = bound[handleType];
                const diff = Math.min(
                    Math.abs(clickHue - value),
                    Math.abs(clickHue - value + 180),
                    Math.abs(clickHue - value - 180)
                );
                
                if (diff < minDiff) {
                    minDiff = diff;
                    activeHandle.current = handleType;
                    activeBoundIndex.current = boundIndex;
                }
            });
        });
        
        isDragging.current = true;
    };

    const handleMouseMove = (e) => {
        if (disabled) {
            canvasRef.current.style.cursor = 'default';
            return;
        }
        const devicePixelRatio = window.devicePixelRatio;

        const canvas = canvasRef.current;

        const width = canvas.width / devicePixelRatio;
        const height = canvas.height / devicePixelRatio;

        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const centerX = width / 2;
        const centerY = height / 2;

        // Check if cursor is near any handle
        const radius = Math.min(width, height) / 2 - 30;
        const isNearHandle = bounds.some(bound => {
            const lowerAngle = hueToAngle(bound.lower) * Math.PI / 180;
            const upperAngle = hueToAngle(bound.upper) * Math.PI / 180;
            const lowerHandleX = centerX + Math.cos(lowerAngle) * (radius - config.cursorHoverOffset);
            const lowerHandleY = centerY + Math.sin(lowerAngle) * (radius - config.cursorHoverOffset);
            const upperHandleX = centerX + Math.cos(upperAngle) * (radius - config.cursorHoverOffset);
            const upperHandleY = centerY + Math.sin(upperAngle) * (radius - config.cursorHoverOffset);
            
            const distance = Math.sqrt(
                Math.pow(x - lowerHandleX, 2) + Math.pow(y - lowerHandleY, 2)
            );
            const upperDistance = Math.sqrt(    
                Math.pow(x - upperHandleX, 2) + Math.pow(y - upperHandleY, 2)
            );
            return distance <= config.handleRadius + 2 || upperDistance <= config.handleRadius + 2; // Slightly larger than handle radius for better UX
        });

        canvas.style.cursor = isNearHandle ? 'grab' : 'default';
        if (isDragging.current) {
            canvas.style.cursor = 'grabbing';
        }

        if (!isDragging.current) return;

        const angle = getAngle(x, y, centerX, centerY);
        const hue = Math.round(angleToHue(angle));
        
        const newBounds = [...bounds];
        const currentBound = newBounds[activeBoundIndex.current];
        
        if (activeHandle.current === 'lower') {
            currentBound.lower = hue;
            if (hue > currentBound.upper) {
                currentBound.upper += 180;
            }
            else if (currentBound.upper - hue > 180) {
                currentBound.upper -= 180;
            }
        } else {
            if (hue < currentBound.lower) {
                currentBound.upper = hue + 180;
            }
            else if (hue - currentBound.lower > 180) {
                currentBound.upper -= 180;
            }
            else {
                currentBound.upper = hue;
            }
        }
        
        onBoundsChange(newBounds);
    };

    const handleMouseUp = () => {
        isDragging.current = false;
        activeHandle.current = null;
    };

    return (
        <Box className={`relative w-[${config.canvasSize}px] h-[${config.canvasSize}px] mt-4`}>
            <canvas
            ref={canvasRef}
            width={config.canvasSize * devicePixelRatio}
            height={config.canvasSize * devicePixelRatio}
            style={{
                width: `${config.canvasSize}px`, 
                height: `${config.canvasSize}px`,
                cursor: disabled ? 'default' : undefined
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            />
        </Box>
    );
};

export default ColorWheel;